<template>
  <div class="dx-viewport">
    <div
      v-on:scroll.passive="sxScrollHandle"
      :id="String.format('{0}-double-scroll-wrapper', gridId)"
      :class="{
        hidden: !isDoubleScrollNeeded || $isMobile(),
      }"
      class="sx-double-scroll-wrapper"
    >
      <div class="double-scroll"></div>
    </div>
    <DxDataGrid
      :ref="gridRefName"
      :id="gridId"
      :data-source="dataSource"
      :word-wrap-enabled="true"
      :allow-column-reordering="!$isMobile()"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      :allow-column-resizing="true"
      :column-resizing-mode="currentResizingMode"
      :column-hiding-enabled="true"
      :column-auto-width="true"
      :focused-row-enabled="false"
      :hover-state-enabled="true"
      :remote-operations="true"
      :cache-enabled="false"
      :noDataText="
        $t(
          'BaseModelFields.NoRecords',
          {},
          { locale: this.$store.state.activeLang }
        )
      "
      :selection="{ mode: 'single' }"
      @selection-changed="selectedChanged"
      @rowDblClick="rowDblClick"
      @row-prepared="onRowPrepared"
      @cell-prepared="onCellPrepared"
      @initialized="initialized"
      @contentReady="contentReady"
      @exporting="onExporting"
      @option-changed="onOptionChanged"
    >
      <DxSorting mode="single" />
      <DxExport :enabled="settings.allowExcelExport" />
      <DxColumnChooser :enabled="true" mode="select">
        <DxPosition
          my="right top"
          at="right bottom"
          of=".dx-datagrid-column-chooser-button"
        />
        <DxColumnChooserSearch :enabled="true" />
      </DxColumnChooser>

      <DxEditing
        :allow-updating="false"
        :allow-deleting="settings.allowDeleting"
        :allow-adding="false"
        mode="row"
      >
        <DxTexts confirm-delete-message="" />
      </DxEditing>

      <DxToolbar>
        <DxItem
          location="before"
          template="orderButtonTemplate"
          locateInMenu="auto"
          :visible="isDragged"
        />
        <DxItem location="after" template="fontSizeChangerTemplate" />
        <DxItem
          location="after"
          locateInMenu="auto"
          :visible="!String.isNullOrWhiteSpace(settings.requestUrl)"
          template="refreshTemplate"
        />
        <DxItem name="exportButton" locateInMenu="auto" />
        <DxItem name="columnChooserButton" locateInMenu="auto" />
        <DxItem name="searchPanel" locateInMenu="auto" />
      </DxToolbar>

      <template #refreshTemplate>
        <div
          class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-template-wrapper dx-item-content dx-toolbar-item-content"
          role="button"
          aria-label="refresh"
          @click="refreshDataGrid"
          tabindex="0"
        >
          <div class="dx-button-content">
            <i
              class="dx-icon dx-icon-refresh"
              :class="{
                'rotate-animation rotate-animation-fast': isLoading,
              }"
            ></i>
          </div>
        </div>
      </template>

      <template #orderButtonTemplate>
        <button
          type="button"
          class="btn btn-success btn-dxgrid-order-save"
          @click="displayOrderSave"
        >
          <i class="bi bi-list-ol"></i>
          {{
            $t(
              "BaseModelFields.DisplayOrderSave",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </template>

      <template #fontSizeChangerTemplate>
        <DxButtonToolbar icon="fontsize" @click="fontSizeChange" />
      </template>

      <DxRowDragging
        v-if="settings.allowDragAndDrop"
        :allow-reordering="true"
        :on-reorder="onReorder"
        drop-feedback-mode="push"
      />

      <DxColumn
        v-for="(item, i) in fixedColumns"
        :key="i"
        :width="item.width"
        :data-field="item.field"
        :data-type="item.type"
        :caption="item.text"
        :cell-template="item.template"
        :format="item.format"
        :alignment="item.textAlign"
        v-model:visible="item.visible"
      />

      <DxColumn
        v-for="(item, i) in columns"
        :key="i"
        :width="item.width > 0 ? item.width : 'auto'"
        :minWidth="
          !String.isNullOrWhiteSpace(item.minWidth)
            ? item.minWidth
            : this.gridColumnDefaultMinWidth
        "
        :data-field="item.field"
        :data-type="item.type"
        :caption="item.text"
        :cell-template="item.template"
        :format="item.format"
        :alignment="item.textAlign"
        v-model:visible="item.visible"
      />

      <!-- Data Import List Page Templates -->
      <template #importBulkRecordDataUploadedFileTemplate="{ data: { data } }">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-success btn-no-custom-style btn-sm"
            @click="fileDownload($event, 'Uploaded', data)"
          >
            <i class="bi bi-file-earmark-arrow-down"></i>
          </button>
        </div>
      </template>

      <template #importBulkRecordDataProcessTypeTemplate="{ data: { data } }">
        <div v-if="data.processTypeId == 1">
          {{
            $t(
              "ImportBulkRecordData.Insert",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
        <div v-else>
          {{
            $t(
              "ImportBulkRecordData.Update",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </template>

      <template #importBulkRecordDataStatusTemplate="{ data: { data } }">
        <div
          v-if="data.status"
          class="fw-bold"
          :class="{
            'text-danger': data.status == 'Failed',
            'text-success':
              data.status == 'Completed' ||
              data.status == 'CompletedWithErrors',
            'text-warning': data.status == 'Processing',
            'text-info': data.status == 'Waiting',
          }"
        >
          {{
            $t(
              "ImportBulkRecordData." + data.status,
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
      </template>

      <template #importBulkRecordDataProcessedFileTemplate="{ data: { data } }">
        <div class="text-center" v-if="data.status !== 'Waiting'">
          <button
            type="button"
            class="btn btn-warning btn-no-custom-style btn-sm"
            @click="fileDownload($event, 'Processed', data)"
          >
            <i class="bi bi-file-earmark-arrow-down"></i>
          </button>
        </div>
      </template>
      <!-- Data Import List Page Templates -->

      <!-- Formula Editor Command Template -->
      <template #formulaEditorCommandTemplate="{ data: { data } }">
        <template
          v-if="
            data.fieldType.includes('Formula') ||
            data.fieldType == 'AutoId' ||
            data.isSystemField
          "
        >
          <div
            class="btn btn-sm btn-no-custom-style btn-primary cursor-default btn-formula-editor disabled dx-template-wrapper"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-original-title="
              $t(
                'BaseModelFields.NoUpdatedFeature',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          >
            <span class="sx-icon">&nbsp;</span>
          </div>
        </template>
        <button
          v-else
          @click="$emit('onFormulaEditorButtonClick', data)"
          type="button"
          class="btn btn-sm btn-no-custom-style btn-primary btn-formula-editor dx-template-wrapper"
          data-bs-toggle="modal"
          data-bs-target="#modalFormulaEditor"
        >
          <span class="sx-icon">&nbsp;</span>
        </button>
      </template>
      <!-- Formula Editor Command Template -->

      <!-- User List Page Templates -->
      <template #permissionGroupTemplate="{ data: { data } }">
        <div class="text-center">
          <a
            v-for="(item, key) in data.permissionGroups"
            :href="`#/PermissionGroup/Detail/${key}`"
            target="_blank"
            class="badge badge-warning me-1"
            :key="key"
          >
            {{ item }}<br />
          </a>
        </div>
      </template>

      <template #userGroupTemplate="{ data: { data } }">
        <div class="text-center">
          <a
            v-for="(item, key) in data.userGroups"
            :href="`#/UserGroup/Detail/${key}`"
            target="_blank"
            class="badge badge-warning me-1"
            :key="key"
          >
            {{ item }}<br />
          </a>
        </div>
      </template>
      <!-- User List Page Templates -->

      <!-- Use Json Viewer Pages Template -->
      <template #jsonViewerTemplate="{ data: { data } }">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-dark"
            data-bs-toggle="modal"
            data-bs-target="#modalJsonViewer"
            @click="onJsonViewerButtonClick($event, data)"
          >
            <i class="bi bi-filetype-json me-2"></i>
            {{
              $t(
                "Components.JsonViewerModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
      <!-- Use Json Viewer Pages Template -->

      <!-- Record Log Pages Template -->
      <template #setXRMAppViewTemplate="{ data: { data } }">
        <div class="text-center">
          <button
            type="button"
            @click="onSetXRMAppViewButtonClick($event, data)"
            class="btn btn-primary"
          >
            <i class="bi bi-browser-chrome me-2"></i>
            {{
              $t(
                "Buttons.RecordView",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>

      <template #recordLogActionTypeTemplate="{ data: { data } }">
        <span class="">
          {{
            $t(
              "RecordLogs." + data.logType,
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </span>
      </template>
      <!-- Record Log Pages Template -->

      <!-- Custom Object Fields Template -->
      <template #customObjectFieldNameTemplate="{ data: { data } }">
        <template
          v-if="
            data.isFirstField ||
            ['CREATEDBY', 'UPDATEDBY', 'CREATEDAT', 'UPDATEDAT'].includes(
              data.formulaName
            )
          "
        >
          <i
            class="bi bi-tag-fill"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-original-title="
              $t(
                'BaseModelFields.CurrentFieldSystemField',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
          ></i>
        </template>
        {{ data.name }}
      </template>
      <template #customObjectFieldTypeTemplate="{ data: { data } }">
        <template v-if="data.inputHtmlType == 'select'">
          <i class="bi bi-three-dots-vertical float-start"></i>
        </template>
        <div>
          <span class="px-1">{{ data.fieldType }}</span>
          <span
            class="badge badge-warning me-1"
            v-if="data.fieldTypeId == 14"
            >{{ data.organizationalUnitName }}</span
          >
          <span
            class="badge badge-warning me-1"
            v-if="data.fieldTypeId == 13"
            >{{ data.lookupObjectName }}</span
          >
          <span
            class="badge badge-warning me-1"
            v-if="data.fieldTypeId == 24"
            >{{ data.aggregateFunction }}</span
          >
          <span class="badge badge-info me-1" v-if="data.isMultipleValue">
            {{
              $t(
                "Fields.MultipleSelect",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
        </div>
      </template>
      <!-- Custom Object Fields Template -->

      <DxColumn
        v-if="!settings.isCommandColumnHide"
        type="buttons"
        :allow-sorting="false"
        :caption="
          this.$t(
            'Buttons.Actions',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
      >
        <template v-if="settings.buttons">
          <DxButton
            @click="actionButtonClick($event, item)"
            v-for="(item, i) in settings.buttons"
            :key="i"
          >
            <template #default>
              <button
                type="button"
                class="btn btn-sm btn-no-custom-style"
                :class="item.cssClass"
                v-bind="item.attributes"
              >
                <i
                  v-if="
                    !String.isNullOrWhiteSpace(item.iconClass) &&
                    !item.iconClass.includes('sx')
                  "
                  class="bi"
                  :class="item.iconClass"
                  v-bind="item.iconAttributes"
                ></i>
                <span v-else :class="item.iconClass">&nbsp;</span>
              </button>
            </template>
          </DxButton>
        </template>

        <DxButton
          :visible="isDeleteButtonVisible"
          :on-click="deleteRow"
          v-if="settings.allowDeleting"
        >
          <template #default>
            <button
              type="button"
              class="btn btn-danger btn-sm btn-no-custom-style"
              data-bs-toggle="modal"
              :data-bs-target="`#modalDelete_${settings.action}`"
            >
              <i class="bi bi-trash"></i>
            </button>
          </template>
        </DxButton>
      </DxColumn>

      <DxSearchPanel
        v-if="settings.allowSearchPanel"
        :visible="true"
        :highlight-case-sensitive="true"
        :search-visible-columns-only="true"
      />
      <DxPager
        :visible="settings.allowPaging"
        :allowed-page-sizes="pageSizes"
        :show-page-size-selector="settings.allowPaging"
        :show-navigation-buttons="settings.allowPaging"
        :show-info="settings.allowPaging"
      />
      <DxPaging :visible="settings.allowPaging" :page-size="pageSize" />
    </DxDataGrid>
  </div>
  <ModalDelete
    v-if="settings.allowDeleting"
    :modalId="`modalDelete_${settings.action}`"
    :deleteRecordId="deleteRecordId"
    :deleteActionUrl="settings.deleteActionUrl"
    :deleteBeforeActionUrl="settings.deleteBeforeActionUrl"
    :isBrsRequest="settings.isBrsRequest"
    :isGatewayRequest="settings.isGatewayRequest"
    @deleteAfterCallMethod="deletedRow"
  />
  <JsonViewerModal :previewData="previewJsonData" />
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxPaging,
  DxColumnChooser,
  DxPosition,
  DxColumnChooserSearch,
  DxSearchPanel,
  DxToolbar,
  DxSorting,
  DxItem,
  DxPager,
  DxRowDragging,
  DxButton,
  DxTexts,
  DxExport,
} from "devextreme-vue/data-grid";

import DxButtonToolbar from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";

//excel export library
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import JsonViewerModal from "@/components/custom/json-viewer/JsonViewerModal";

export default {
  name: "Grid",
  props: {
    settings: {
      type: Object,
      default() {
        return {};
      },
      information: {
        action: "Example",
        requestUrl: "/Example/List?staticProperty=1", //or "/Example/List"

        requestUrlRouteParamNames: [
          //key: parameter name, value: parameter value in route
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "pageLayoutId", //route required parameter name
            value: "publicId", //row data field
            isRouteParameter: false, //switch route parameter value or current row data
          },
          {
            key: "customObjectId", //route required parameter name
            value: "customObjectId", //route parameter value
            isRouteParameter: true, //switch route parameter value or current row data
          },
        ],
        buttons: [
          {
            name: "", // button name
            cssClass: "", //button classes in bootstrap 5 or custom classes in assets stylesheets
            iconClass: "bi-", //i classes in bootstrap 5 icons or custom classes in assets stylesheets
            routeButton: false, // router push?
            emitMethodName: "onJsonViewerButtonClick", //parent emit method name
            attributes: {
              //example
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalJsonViewer",
            },
          },
          {
            name: "",
            cssClass: "",
            iconClass: "bi-",
            routeButton: true, //on router push and router object is required
            routeObj: {
              name: "CustomObjectEditButton", //name in router.js
              params: {
                customObjectId: "",
                buttonId: "",
              },
            },
          },
        ],

        httpRequestType: "GET",
        isPublicIdColumnHide: false,
        isCommandColumnHide: false,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        autoOrderProcess: false,

        isCalcGridHeight: false,

        isBrsRequest: false,
        isGatewayRequest: false,

        allowDragAndDrop: false, //only local data binding
        changeOrderUrl: "",
        changeOrderModelParentProperties: [
          { key: "parentId", value: "customObjectId" },
          { key: "parent2", value: "businessRuleId" },
        ], //in value only router parameters

        allowDeleting: false,
        deleteBeforeActionUrl: "",
        deleteActionUrl: "",
      },
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
      information: {
        text: "string", //column localize caption
        field: "string", //row data in field
        type: "string", //'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
        visible: "boolean", //column is visible
        width: "number", //column width value
        minWidth: "number", //column min width value
        // maxWidth: "number", //column max width value !! not supported
        template: "string", //column template
        format: "string", //column data view format
        textAlign: "string", //'left' | 'center' | 'right'
      },
    },
    //!! local data binding
    //this prop for this to work, the request url parameter in the settings object must be empty.
    rows: {
      type: Array,
      default: () => [],
      required: false,
    },
    pageNumberStartFrom: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    //base emits
    "onRequestFinally", // fire when the request is finished
    "onReturnRequestResponse", //fire when the request returns
    "onRequestUnSuccessful", // fire if the request fails (isOk:false)
    "changeOrderSuccessful", // fire change order after if the request success
    "heightInfo",

    //page specific emits

    //action: CustomObjectPageLayouts
    "onJsonViewerButtonClick",
    "onDesignerButtonClick",
    "onJsonViewerCopyPageLayoutButtonClick",
    "onJsonViewerPastePageLayoutButtonClick",
    "clonePageLayout",

    //action: CustomObjectBusinessRules
    "onBusinessRuleActionButtonClick",

    //action: CustomObjectBusinessRuleActions
    "onEditButtonClick",

    //action: CustomObjectFields
    "onFieldDiagramButtonClick",
    "onTranslationButtonClick",

    //action: CustomObjectViewFilters
    "copyViewFilterButtonClick",

    //action: BusinessProcessList
    "onBpmnDesignerButtonClick",

    //action: On Used Formula Editor Pages
    "onFormulaEditorButtonClick",

    //action: ImportBulkRecordDataList
    "onMonitoringButtonClick",
    "onFileDownload",
    "onBulkImportCancelButtonClick",

    //action: UserList
    "onUserPasswordChangeButtonClick",
    "onUndoDeletedUserButtonClick",

    //action: MenuItemListDetail
    "onDetailButtonClick",

    //action: ProjectList
    "onCompanyClearCacheButtonClick",
    "onCompanyResetCacheButtonClick",

    //action: ModelChangeModal
    "onModelButtonClick",

    //action: SetCodeFunctionList
    "onDebugButtonClick",
  ],
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxButton,
    DxToolbar,
    DxSorting,
    DxItem,
    DxColumnChooser,
    DxPosition,
    DxColumnChooserSearch,
    DxSearchPanel,
    DxPager,
    DxRowDragging,
    DxTexts,
    DxExport,
    DxButtonToolbar,

    JsonViewerModal,
  },
  data() {
    return {
      allowDragAndDrop: this.settings.allowDragAndDrop,
      isDoubleScrollNeeded: false,
      gridId: `grid_${this.settings.action}`,
      gridColumnDefaultMinWidth: 100,
      fixedColumns: [
        {
          text: this.$t(
            "BaseModelFields.SerialNo",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "displayOrder",
          type: "string",
          visible: true,
          width: 50,
          template: "",
          format: "",
          textAlign: "center",
        },
      ],
      publicIdColumn: {
        text: this.$t(
          "BaseModelFields.PublicId",
          {},
          { locale: this.$store.state.activeLang }
        ),
        field: "publicId",
        type: "string",
        visible: false,
        width: 350,
        template: "",
        format: "",
        textAlign: "",
      },
      gridRefName: "grid",
      deleteRecordId: null,
      systemFormulaNames: ["CREATEDBY", "UPDATEDBY", "CREATEDAT", "UPDATEDAT"],
      currentResizingMode: this.$isMobile() ? "nextColumn" : "widget",
      pageSizes: [10, 25, 50, 100, 500],
      pageSize: this.settings.allowPaging ? 50 : 500,
      defaultFontSize: 14,
      maxFontSize: 20,
      selectedRowIndex: -1,
      isDragged: false,
      dataSource: null,
      primaryKey: "publicId",
      previewJsonData: null,
      isLoading: true,
    };
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName]
        ? this.$refs[this.gridRefName].instance
        : null;
    },
  },
  created() {
    if (!String.isNullOrWhiteSpace(this.settings.requestUrl)) {
      this.getData();
    } else if (this.rows.length > 0) {
      //local data
      this.dataSource = this.rows;
    }

    if (!this.settings.isPublicIdColumnHide) {
      this.fixedColumns.push(this.publicIdColumn);
    }
  },
  mounted() {
    // var parentContent = $(String.format("#{0}", this.gridId)).parents(
    //   ".customobject-detail"
    // );
    // if (!this.$isMobile()) {
    //   var self = this;
    //   if (parentContent.length > 0) {
    //     $(".customobject-detail")
    //       .off("scroll")
    //       .on("scroll", function () {
    //         self.onDivScrolling($(".customobject-detail"));
    //       });
    //   } else {
    //     $(window)
    //       .off("scroll")
    //       .on("scroll", function () {
    //         self.onWindowScrolling();
    //       });
    //   }
    // }
    if (!this.$isMobile()) {
      var self = this;
      $(window)
        .off("scroll")
        .on("scroll", function () {
          self.onWindowScrolling();
        });
    }
  },
  watch: {
    "$route.params.customObjectId"(newValue) {
      if (
        !String.isNullOrWhiteSpace(newValue) &&
        !String.isNullOrWhiteSpace(this.settings.requestUrl)
      ) {
        this.getData();
      }
    },
    "settings.requestUrl": {
      deep: true,
      handler() {
        if (!String.isNullOrWhiteSpace(this.settings.requestUrl)) {
          this.getData();
        }
      },
    },
    rows: {
      deep: true,
      handler() {
        this.dataSource = this.rows;
        this.grid.refresh();
      },
    },
  },
  methods: {
    refreshDataGrid() {
      this.grid.refresh();
    },
    onSetXRMAppViewButtonClick($event, data) {
      window.open(
        `${this.$config.webSites.app}/set/detail/${this.$parent.logRecordCustomObject.key}/${data.recordPublicId}`
      );
    },
    onJsonViewerButtonClick($event, data) {
      var columnFormulaName = $($event.target)
        .closest("td")
        .data("columnformulaname");
      this.previewJsonData = String.isNullOrWhiteSpace(columnFormulaName)
        ? null
        : data[columnFormulaName];
    },
    fileDownload($event, type, data) {
      $($event.currentTarget).prop("disabled", true);
      this.$emit("onFileDownload", {
        recordId: data.publicId,
        name: `${type}_${data.fileName}`,
        type: type,
      });
    },
    fontSizeChange(e) {
      var nextFontSize = ++this.defaultFontSize;
      if (nextFontSize >= this.maxFontSize) {
        nextFontSize = 14;
        this.defaultFontSize = nextFontSize;
      }

      $(
        `#${this.gridId} .dx-datagrid, #${this.gridId} .btn-dx-grid-title, #${this.gridId} .dx-datagrid .dx-page-sizes, #${this.gridId} .dx-datagrid .dx-pages`
      ).css("font-size", `${nextFontSize}px`);
    },
    initialized(e) {
      //ui before
    },
    contentReady(e) {
      //ui after
      this.configurationSxScroll();
      this.calcGridHeight();
    },
    calcGridHeight() {
      if (this.settings.isCalcGridHeight) {
        this.$emit("heightInfo", $("#" + this.gridId).height());
      }
    },
    configurationSxScroll() {
      var contentScroll = $(
          String.format("#{0} .dx-datagrid-content:first", this.gridId)
        )[0],
        sxScroll = $(
          String.format(
            "#{0}-double-scroll-wrapper .double-scroll",
            this.gridId
          )
        );

      this.isDoubleScrollNeeded =
        contentScroll.scrollWidth > contentScroll.clientWidth;

      sxScroll.width(contentScroll.scrollWidth);
    },
    sxScrollHandle() {
      var dxScroll = this.grid.getScrollable(),
        customScrollLeftMargin = $(
          String.format("#{0}-double-scroll-wrapper", this.gridId)
        ).scrollLeft();

      dxScroll.scrollTo({ left: customScrollLeftMargin });
    },
    onWindowScrolling() {
      var scroll = $(String.format("#{0}-double-scroll-wrapper", this.gridId)),
        grid = $(String.format("#{0}", this.gridId)),
        sxHeader = $(".app-header"),
        headerPanel = grid.find(".dx-datagrid-header-panel"),
        header = grid.find(".dx-datagrid-headers"),
        isWindowScrolling = $(window).scrollTop() > 0,
        top = 0,
        opacity = 1;

      if (isWindowScrolling) {
        opacity = 0.7;
        top = sxHeader.height() + headerPanel.height() + header.height() + 15;
      }

      var cssObj = {
        top: top,
        opacity: opacity,
      };
      scroll.css(cssObj);

      // return cssObj;
    },
    onDivScrolling(div) {
      var scroll = $(String.format("#{0}-double-scroll-wrapper", this.gridId)),
        grid = $(String.format("#{0}", this.gridId)),
        newBlock = $(".new-block"),
        headerPanel = grid.find(".dx-datagrid-header-panel"),
        header = grid.find(".dx-datagrid-headers"),
        isDivScrolling = div.scrollTop() > 0,
        top = 0,
        opacity = 1;

      if (isDivScrolling) {
        opacity = 0.7;
        top = newBlock.height() + headerPanel.height() + header.height();
      }

      scroll.css({
        top: top,
        opacity: opacity,
      });
    },
    onExporting(e) {
      var excelFileName = String.format("{0}.xlsx", this.settings.action);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        this.$t(
          "BaseModelFields.MainSheet",
          {},
          { locale: this.$store.state.activeLang }
        )
      );

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = { name: "Arial", size: 12 };
          options.excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            excelFileName
          );
        });
      });
      e.cancel = true;
    },
    actionButtonClick($event, button) {
      var rowData = $event.row.data;
      if (button.routeButton) {
        var routeObj = button.routeObj;
        this.settings.routeRequiredParameters.forEach((element) => {
          if (element.isRouteParameter) {
            routeObj.params[element.key] = this.$route.params[element.value];
          } else {
            routeObj.params[element.key] = rowData[element.value];
          }
        });

        this.$router.push(routeObj);
      } else if (!String.isNullOrWhiteSpace(button.emitMethodName)) {
        this.$emit(button.emitMethodName, rowData, $event, this.dataSource);
      }
    },
    onRowPrepared(e) {
      if (e.rowType == "data") {
        e.rowElement.classList.add("sx-item");
        if ("publicId" in e.data) {
          e.rowElement.setAttribute("data-id", e.data.publicId);
        }
      }
    },
    onCellPrepared(args) {
      if (!String.isNullOrWhiteSpace(args.column.dataField)) {
        String.SetAttributes(args.cellElement, {
          "data-columnformulaname": args.column.dataField,
        });
      }

      if (args.rowType === "data") {
        var displayOrderVisibleIndex =
          this.grid.getVisibleColumnIndex("displayOrder");
        if (displayOrderVisibleIndex !== -1) {
          if (args.columnIndex == displayOrderVisibleIndex) {
            var serialNumberColumn = args.cellElement,
              rowIndex = ++args.rowIndex,
              page = args.component.pageIndex(),
              pageSize = args.component.pageSize(),
              startIndex = page * pageSize,
              text = "";

            if (this.settings.allowPaging) {
              text = startIndex + rowIndex;
            } else {
              text = rowIndex;
            }

            if (!this.settings.autoOrderProcess) {
              text = args.data["displayOrder"];
            }

            if (!String.isNullOrWhiteSpace(this.settings.serialNoLink)) {
              serialNumberColumn.innerHTML = `<a class="text-decoration-none" href="${this.settings.serialNoLink.replace(
                "__CUSTOMOBJECTPUBLICID__",
                this.$route.params.customObjectId
              )}${args.data[this.primaryKey]}">${text}</a>`;
            } else {
              serialNumberColumn.innerText = text;
            }
          }
        }

        if (this.settings.action == "ImportBulkRecordDataList") {
          if (
            "status" in args.data &&
            args.column.type == "buttons" &&
            (args.data.status == "Waiting" ||
              String.isNullOrWhiteSpace(args.data.status))
          ) {
            $(args.cellElement).html("");
          }
        }
      }
    },
    selectedChanged(e) {
      this.selectedRowIndex = e.component.getRowIndexByKey(
        e.selectedRowKeys[0]
      );
    },
    isDeleteButtonVisible(e) {
      if (this.settings.action == "CustomObjectFields") {
        return (
          !e.row.data.isFirstField &&
          !this.systemFormulaNames.includes(e.row.data.formulaName)
        );
      }

      return true;
    },
    deleteRow(e) {
      this.deleteRecordId = e.row.data.publicId;
    },
    deletedRow() {
      var deletedRecordId = this.deleteRecordId;
      if (!String.isNullOrWhiteSpace(this.settings.requestUrl)) {
        this.getData();
      } else {
        //local data binding
        this.dataSource = this.dataSource.filter(function (f) {
          return f.publicId != deletedRecordId;
        });
        this.grid.refresh();
      }

      this.deleteRecordId = null;
    },
    onReorder(e) {
      if (!this.allowDragAndDrop) {
        createToast(
          this.$t(
            "FieldErrors.NoOrderChanges",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      const visibleRows = e.component.getVisibleRows();
      if (visibleRows.length < 2) return;

      this.isDragged = true;
      const toIndex = this.dataSource.findIndex(
          (item) => item.publicId === visibleRows[e.toIndex].data.publicId
        ),
        fromIndex = this.dataSource.findIndex(
          (item) => item.publicId === e.itemData.publicId
        ),
        newTasks = [...this.dataSource];

      newTasks.splice(fromIndex, 1);
      newTasks.splice(toIndex, 0, e.itemData);
      this.dataSource = newTasks;
    },
    displayOrderSave() {
      if (!this.allowDragAndDrop) {
        createToast(
          this.$t(
            "FieldErrors.NoOrderChanges",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.isDragged = false;
      const visibleRows = this.grid.getVisibleRows();
      var i = 1,
        displayOrderModel = {
          items: [],
        };

      if (this.settings.changeOrderModelParentProperties.length > 0) {
        this.settings.changeOrderModelParentProperties.forEach((element, i) => {
          displayOrderModel[element.key] = this.$route.params[element.value];
        });
      }

      visibleRows.forEach((record) => {
        displayOrderModel.items.push({
          key: record.data.publicId,
          value: (i++).toString(),
        });
      });

      var instance = this.$appAxios;
      if (this.settings.isGatewayRequest) {
        instance = this.$prodGatewayAxios;
      } else if (this.settings.isBrsRequest) {
        instance = this.$brsAxios;
      }

      instance
        .post(this.settings.changeOrderUrl, displayOrderModel)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            //parent notification
            this.$emit("changeOrderSuccessful");

            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            createToast(
              this.$t(
                "Messages.ErrorMesage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getData() {
      var settings = this.settings,
        route = this.$route,
        appAxios = this.$appAxios,
        brsAxios = this.$brsAxios,
        prodGatewayAxios = this.$prodGatewayAxios,
        self = this;

      this.dataSource = new CustomStore({
        key: "",
        load(loadOptions) {
          self.isLoading = true;

          var page = $(String.format("#grid_{0}", settings.action))
              .find(".dx-page.dx-selection")
              .text(),
            reqUrl = settings.requestUrl,
            requestParameters = [];

          if (!String.isNullOrWhiteSpace(settings.requestUrlRouteParamNames)) {
            settings.requestUrlRouteParamNames.forEach((element) => {
              requestParameters.push({
                key: element.key,
                value: route.params[element.value],
              });
            });
          }

          if (loadOptions.sort) {
            var firstSortOption = loadOptions.sort[0];
            requestParameters.push({
              key: "sort",
              value: `${firstSortOption.selector.toUpperCase()}|${
                firstSortOption.desc ? "desc" : "asc"
              }`,
            });
          }

          if (loadOptions.filter) {
            requestParameters.push({
              key: "q",
              value: loadOptions.filter[0].filterValue,
            });
          }

          if (settings.allowPaging) {
            if (self.pageNumberStartFrom == 0) {
              page =
                parseInt(
                  String.isNullOrWhiteSpace(page)
                    ? self.pageNumberStartFrom
                    : page
                ) - 1;
            } else if (String.isNullOrWhiteSpace(page)) {
              page = loadOptions.skip == 0 ? 1 : loadOptions.skip;
            }
            var pageSize = self.pageSize;
            if (!String.isNullOrWhiteSpace(loadOptions.take)) {
              pageSize = loadOptions.take;
            }
            requestParameters.push({ key: "page", value: page });
            requestParameters.push({ key: "size", value: pageSize });
          }

          if (requestParameters.length > 0) {
            requestParameters.forEach((element) => {
              var separator = reqUrl.includes("?") ? "&" : "?";
              reqUrl += `${separator}${element.key}=${element.value}`;
            });
          }

          var instance = appAxios;
          if (settings.isGatewayRequest) {
            instance = prodGatewayAxios;
          } else if (settings.isBrsRequest) {
            instance = brsAxios;
          }

          var httpRequest =
            settings.httpRequestType === "POST"
              ? instance.post(reqUrl)
              : instance.get(reqUrl);

          return httpRequest
            .then((response) => {
              var result = response.data;
              if ("isOk" in result && "items" in result) {
                if (!result.isOk) {
                  self.$emit("onRequestUnSuccessful", result);
                }

                //modify item count
                if ("itemCount" in result) {
                  //
                } else {
                  response.data.itemCount = result.items.length;
                }
              } else {
                //modify data object
                response = {
                  data: {
                    items: result,
                    itemCount: result.length,
                  },
                };
              }

              self.$emit("onReturnRequestResponse", response);

              return response;
            })
            .then((data) => ({
              data: data.data.items,
              totalCount: data.data.itemCount,
            }))
            .catch(function (error) {
              self.$emit("onRequestUnSuccessful", error);
            })
            .finally(() => {
              self.isLoading = false;
              self.$emit("onRequestFinally");
            });
        },
      });
    },
    rowDblClick(e) {
      var self = this;
      if (this.settings.action == "CustomObjectFields") {
        Swal.fire({
          html:
            "<span class='fw-bold fs-6'>" +
            this.$t(
              "Fields.FieldValueCopyMessage",
              {},
              { locale: this.$store.state.activeLang }
            ) +
            "</span>",
          showDenyButton: true,
          denyButtonText: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          buttonsStyling: false,
          confirmButtonText: "Public Id",
          customClass: {
            confirmButton: "btn fw-bold me-2 btn-success",
            denyButton: "btn fw-bold btn-info ",
          },
        }).then((result) => {
          if (result.isDenied) {
            self.$root.copyClipboard(
              e.data.formulaName,
              this.$t(
                "Fields.FormulaName",
                {},
                { locale: this.$store.state.activeLang }
              )
            );
          } else if (result.isConfirmed) {
            self.$root.copyClipboard(e.data.publicId, "Public Id");
          }
        });
      }
    },
    onOptionChanged(e) {
      if (e.name === "columns" && e.fullName.endsWith("width")) {
        this.configurationSxScroll();
      } else if (e.name == "searchPanel" && e.fullName === "searchPanel.text") {
        this.allowDragAndDrop = String.isNullOrWhiteSpace(e.value);
        this.scrollToTop();
      }
    },
    scrollToTop() {
      const customObjectScroll = document.querySelector(".customobject-detail");

      if (customObjectScroll) {
        customObjectScroll.scrollTop = 0;
      }
    },
  },
};
</script>
