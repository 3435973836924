export default {
  "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
  "Slogan1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilginin Yaşam"])},
  "Slogan2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boyu Yönetimi"])},
  "ProductName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Yönetim Paneli"])},
  "Sidebar": {
    "Apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama"])},
    "HomePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
    "CustomObjectManagements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Yönetimi"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesneler"])},
    "ProjectGeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Genel Ayarları"])},
    "DataSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Özeti"])},
    "Holidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatil Günleri"])},
    "CustomObjectDeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Silme Kuralları"])},
    "SystemLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Logları"])},
    "RecordLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Logları"])},
    "FormulaErrorLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Hata Logları"])},
    "SystemLoginInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Giriş Bilgileri"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Ayarları"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa"])},
    "SuperAdminSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Süper Yönetici Ayarları"])},
    "Projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
    "FormulaErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Hataları"])},
    "UsageCounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Sayıları"])},
    "MenuManagements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Yönetimi"])},
    "Menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menüler"])},
    "OrganizationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizasyon Yönetimi"])},
    "UserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grupları"])},
    "PermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grupları"])},
    "RestrictedPermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısıtlı Yetki Grupları"])},
    "DatabaseSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı Ayarları"])},
    "Configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurasyonlar"])},
    "ProjectParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Parametreleri"])},
    "BusinessProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreçleri"])},
    "BusinessProcessManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreçleri Yönetimi"])},
    "ApiToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entegrasyon Api Anahtarları"])},
    "ImportBulkRecordData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Veri Yükleme"])},
    "SetCodeFunctions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Fonksiyonları"])},
    "Functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyonlar"])},
    "HttpConnections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Http Bağlantıları"])},
    "SetCodeDatabases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Tabanı Bağlantıları"])},
    "SetCodeModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modeller"])},
    "SetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode"])},
    "SetModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetModel"])},
    "DashboardReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporları"])},
    "XtraReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Raporları"])},
    "LocalizationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Ayarları"])},
    "SystemMessageTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Mesaj Şablonları"])},
    "PageLayoutTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarım Şablonları"])},
    "MultiLanguageSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu Dil Anahtarları"])},
    "ProjectDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Tasarımı"])},
    "CompanyInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Bilgileri"])}
  },
  "Home": {
    "CustomObjectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Sayısı"])},
    "UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Sayısı"])},
    "FieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Alan Sayısı"])}
  },
  "Header": {
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara..."])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BİLDİRİMLER"])}
  },
  "ApiToken": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Anahtarı"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Api Anahtarı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Anahtarı Düzenle"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Api Anahtarı"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Anahtarı İsmi"])},
    "Token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan Api Anahtarı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "UsageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Sayısı"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Kullanıcısı"])}
  },
  "Language": {
    "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])}
  },
  "FieldOperators": {
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["="])},
    "greather_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">"])},
    "greather_than_or_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">="])},
    "less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<"])},
    "less_than_or_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<="])},
    "not_equal_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<>"])},
    "starts_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İle Başlar"])},
    "ends_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İle Biter"])},
    "includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerenler"])},
    "not_includes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçermeyenler"])},
    "in_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçinde"])},
    "out_radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışında"])},
    "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin İçeren"])},
    "does_not_contain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin İçermeyen"])},
    "between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arasında"])}
  },
  "FieldTypeDescriptions": {
    "text_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar en fazla 450 karaktere kadar yazı kutusu ekleyebilirler"])},
    "textarea_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar en fazla 50.000 karaktere kadar yazı alanı ekleyebilirler"])},
    "number_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar tam ve ondalıklı sayılar ekleyebilirler"])},
    "checkbox_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar bir durumun var olup olmadığını kontrol eden, Evet/Hayır, Aktif/Pasif gibi cevaplar isteyen sorular için ekleyebilirler"])},
    "email_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar email ekleyebilirler. Ekleme ve güncelleme işlemlerinde email onayı ve outlook link entegrasyonu yapılır"])},
    "phone_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar 4 rakamlık ülke kodunu da bulunduran telefon numarasını ekleyebilirler"])},
    "url_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar URL adresi ekleyebilirler. Ekleme ve güncelleme işlemlerinde http:// maskesi vardır. Değerler  liste ve detay sayfasında link olarak görünür"])},
    "datetime_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar Gün/Ay/Yıl Saat:Dakika formatında tarih ve saat ekleyebilirler"])},
    "date_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar Gün/Ay/Yıl formatında tarih ekleyebilirler"])},
    "time_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar Saat:Dakika formatında saat ekleyebilirler"])},
    "image_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar resim yükleyebilir ve yüklenen resmi detay sayfasında açabilir"])},
    "file_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar dosya yükleyebilir ve yüklenen dosyaları detay sayfasında açabilir"])},
    "html_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar html tipte alan ekleyebilirler"])},
    "selectlist_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar tanımladıkları listeden bir veye birden fazla öğe seçebilirler"])},
    "lookup_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar seçili liste olarak lookup ekleyebilirler. Lookup'ın seçili liste öğesi diğer nesnenin kayıdıdır"])},
    "system_lookup_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar seçili liste olarak lookup ekleyebilir. Lookup'ın seçili liste öğesi kullanıcı ya da kullanıcı grupları olabilir"])},
    "predefined_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar sisteme seçili liste olarak önceden tanımlı liste ekleyebilir; İl, İlçe, Sektör, Alt-Sektör"])},
    "encrypted_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar Şifrelenmiş tipte alan ekleyebilirler"])},
    "spatial_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar harita tipinde alan ekleyebilirler"])},
    "auto_id_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar Auto ID alan ekleyebilirler"])},
    "read_only_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar sadece okunan tipte alan ekleyebilirler"])},
    "percent_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numaranın değeri sisteme yüzdelik olarak eklenir. Detay ve liste sayfalarında numaranın yanında '%' ikonu vardır."])},
    "double_precision_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen alana ondalıklı sayılar girilebilir."])},
    "long_integer_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen alan tam sayılar girilebilir."])},
    "decimal_field_type_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçilen alana tam ve ondalıklı sayılar girilebilir. Double Precision alana göre bellekte daha fazla yer kaplar."])},
    "formula_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar önceden belirlenen formül ile işlem yapabilir"])},
    "rollup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar ilişkili nesnelerdeki alanlarla hesaplama yapabilir"])}
  },
  "BaseModelFields": {
    "CreatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturan Kişi"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma Tarihi"])},
    "UpdatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleyen Kişi"])},
    "UpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenme Zamanı"])},
    "PasswordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz şifreler eşleşmiyor!"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrele"])},
    "SerialNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
    "NoRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Bulunamadı"])},
    "NoResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gösterilecek kayıt bulunamadı"])},
    "MaxElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum eleman sayısına ulaştınız"])},
    "ClickToChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçim yapmak için tıklayın"])},
    "ClickToDeSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçimi kaldırmak için tıklayın"])},
    "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili"])},
    "ChoosePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçiniz"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "SaveChangesAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve Kapat"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım"])},
    "SelectlistWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu seçim veya Tekli seçim olarak açılan alanın sonrasında bu özelliğin güncellenmesi mümkün değildir."])},
    "NoUpdatedFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu özellik değiştirilemez"])},
    "DisplayOrderSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralamayı Kaydet"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
    "ChangeTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema Değiştir"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyalandı"])},
    "CurrentFieldSystemField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan sistem tarafından otomatik olarak oluşturulmuştur"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Ayarlar"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "AlertUnSavedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilmemiş değişiklikleriniz mevcut. Bu sayfadan ayrılmak istiyor musun?"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "MainSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statü"])},
    "Explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Ayarlar"])},
    "TriggerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetikleme Ayarları"])},
    "PublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Id"])},
    "RequiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu Alan"])},
    "Successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatalı"])},
    "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklenen"])},
    "DeletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinme Zamanı"])},
    "FailedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız Olma Zamanı"])},
    "SucceededAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı Olma Zamanı"])},
    "PerformanceDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performans Süresi"])},
    "Latency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçikme"])},
    "ExceptionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Tipi"])},
    "ExceptionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Mesajı"])},
    "ExceptionDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Detayı"])},
    "ChangeLanguageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ayarınız güncellendi, Lütfen tekrar giriş yapınız."])},
    "ChangeLanguageWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil ayarınız güncelleniyor, lütfen bekleyiniz..."])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "UnexpectedErrorHasOccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklenmeyen bir hata oluştu"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyala"])},
    "SwitchFullScreenMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam ekran moduna geçmek için editör seçiliyken Esc tuşuna basın"])},
    "SaveShortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hızlı güncelleme işlemi için editör seçiliyken CTRL + S tuşlarına basın. (Bu işlem liste sayfasına yönlendirmez.)"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "Passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])}
  },
  "CustomObjects": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesneler"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Nesne"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Nesne"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Düzenle"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Detay"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "PluralName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoğul Adı"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "FieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Alan Sayısı"])},
    "RecordCopiedIsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Kopyalama etkin olsun mu"])},
    "FirstFieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk Alan Tipi"])},
    "RequiredFieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Alan Adı"])},
    "RequiredFieldFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Alan Formül Adı"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])},
    "IsCalculatedOnlyOnInsert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece yeni kayıt sırasında mı hesaplansın?"])},
    "TextField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin Alanı"])},
    "FormulaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Alanı"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımları"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtreleri"])},
    "ObjectRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirmeleri"])},
    "ValidationRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon Kuralları"])},
    "BusinessRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralları"])},
    "Scripts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel JS Kodları"])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butonlar"])},
    "FormTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Şablonları"])},
    "KanbanSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Ayarları"])},
    "CalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Ayarları"])},
    "MapSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita Ayarları"])},
    "TreeViewSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağaç Görünümü Ayarları"])},
    "DeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Kuralları"])},
    "Kanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Ayarları"])},
    "Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita Ayarları"])},
    "CreatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde oluşturuldu"])},
    "UpdatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarihinde güncellendi"])},
    "WFieldCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALAN SAYISI"])},
    "ValidationRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALİDASYON KURALI SAYISI"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İŞ KURALI SAYISI"])},
    "ScriptCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖZEL JS KODLARI"])},
    "RecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYIT SAYISI"])},
    "ButtonCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BUTON SAYISI"])},
    "ObjectRelationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NESNE İLİŞKİLENDİRME SAYISI"])},
    "BusinessRuleActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyonları"])},
    "DependencyFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan İlişkilendirmeleri"])},
    "MessageTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonları"])},
    "BrCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objeye Ait İş Kuralları"])},
    "ShowExampleLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek Linkleri Göster"])},
    "HideExampleLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Örnek Linkleri Gizle"])},
    "RelationalRecordDeleteCustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinecek İlişkili Nesne Tanımlama"])},
    "Relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne İlişkileri"])}
  },
  "ValidationRules": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Validasyon Kuralı"])},
    "NewValidationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Validasyon Kuralı"])},
    "EditValidationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon Kuralı Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "ValidationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulama Tipi"])},
    "ValidationTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
    "ValidationTypeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı"])},
    "ValidationTypeInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "ValidationTypeDetailBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Engelleme"])},
    "ValidationTypeEditBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenleme Engelleme"])},
    "ValidationTypeDetailForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Sayfası Yönlendirme"])},
    "ValidationTypeEditForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenleme Sayfası Yönlendirme"])},
    "ValidationTypeValidationForComponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komponent için Validasyon"])},
    "ValidationTypeValidationForButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton için Validasyon"])},
    "ValidationTypeDeleteBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Engelleme"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])},
    "FormulaInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyonun çalışabilmesi için yazılan formül true dönmelidir. Bu uyarı bilgilendirme amaçlıdır."])},
    "ErrorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Mesajı"])},
    "IsShowMessageInModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajı Modal Olarak Göster"])},
    "IsCheckOnFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formülde sayfa tasarımı kontrol ediliyor"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "ViewFilters": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Görüntüleme Filtresi"])},
    "NewViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Görüntüleme Filtresi"])},
    "EditViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "SystemViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Görüntüleme Filtresi"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Ayarlar"])},
    "Criterias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterler"])},
    "Columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralamalar"])},
    "Totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplamlar"])},
    "TotalField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Alanı"])},
    "TotalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Tipi"])},
    "VisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünen Alanlar"])},
    "HiddenFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizli Alanlar"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "IsDefaultFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "IsSystemFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem"])},
    "IsEditInlineOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satırda Güncelleme Etkin"])},
    "IsEditInlineButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa da Değiştirilebilir Güncelleme Ayarı"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Fonksiyon Butonları"])},
    "FilterSearchBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "InstantFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlık Filtreleme"])},
    "KanbanButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban"])},
    "CalendarButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim"])},
    "MapButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita"])},
    "ExportButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışarı Aktar"])},
    "BulkProcessButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu İşlem"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "TreeButtonGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağaç Görünümü"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "RelationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişki Ayrıntısı"])},
    "IsRelationDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişki Detayını Göster"])},
    "ActiveActionButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Aksiyon Butonları"])},
    "LookupRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Nesne"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralama"])},
    "OrderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralama Alanı"])},
    "OrderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıralama Tipi"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artan"])},
    "Descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azalan"])},
    "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topla"])},
    "Avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama"])},
    "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say"])},
    "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks"])},
    "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "CriteriaFieldsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterleme yapılacak alan bulunamadı."])},
    "DuplicateCriteriaItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterleriniz de yinelenen öğeler mevcut. Lütfen kontrol ediniz."])},
    "MaxCriteriaItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum 11 tane kriter ekleyebilirsiniz. Lütfen kontrol ediniz."])},
    "EnterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer giriniz"])},
    "PleaseAddTheFieldToDisplayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen görüntülenecek alan(lar)ı ekleyip tekrar deneyiniz."])},
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butonlar"])},
    "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton"])},
    "ColumnsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünen veya gizli alanlarınızı yönetin"])},
    "DesignButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım Aracını Aç"])},
    "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butonları Önizle ve Sıralama Değiştir"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "CriteriaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "CriteriaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Adı"])},
    "CriteriaOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatör"])},
    "IsPageFilterCollapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa filtresi kapalı gelsin mi?"])},
    "FieldOrOperatorNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen alan ve operatör seçimlerini tamamlayınız"])},
    "PageCriteriaNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz kriter eklemediniz"])},
    "IsCellEditingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hücrede Güncelleme Etkin"])},
    "SaveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve Yeni Oluştur"])},
    "AllAppendVisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Alanları Görünür Olarak Ekle"])},
    "AllRemoveVisibleFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Görünür Alanları Temizle"])},
    "TableWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo Genişliği"])},
    "ColumnWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun Genişliği"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "ObjectRelations": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Nesne İlişkilendirme"])},
    "NewObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Nesne İlişkilendirme"])},
    "EditObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirme Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "RelationLookupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Lookup Adı"])},
    "LookupObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Nesne"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Adı"])},
    "RelationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Alan"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Boyutu"])},
    "IsEditInline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satırda Güncelleme Etkin"])},
    "IsAddInline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satırda Yeni Kayıt Girişi Etkin"])},
    "ShowInNewPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kayıt sayfasında görüntülensin"])},
    "ShowInEditPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme sayfasında görüntülensin"])},
    "IsItLoadedOnFirstRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa çağrıldığında yüklü gelsin mi?"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "KanbanView": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kanban"])},
    "NewKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kanban Ayarı"])},
    "EditKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Ayarı Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "KanbanColumnField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Kolon Adı"])},
    "HeaderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Başlık"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "CalculatedField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplamı Alınacak Alan"])},
    "CalculatedFieldSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama Alanı Birimi"])},
    "AggregateFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama Fonksiyonu"])},
    "CountSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam Sayı"])},
    "AggregateSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topla"])},
    "AggregateAverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortala"])},
    "AggregateCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say"])},
    "AggregateMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks"])},
    "AggregateMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "MapView": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Harita"])},
    "NewMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Harita Ayarı"])},
    "EditMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita Ayarı Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "SpatialField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasyon Alan"])},
    "LabelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Objesi"])},
    "DefaultMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Harita"])},
    "OpenStreetMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Street Map"])},
    "Google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "Atlas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlas"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "SetScript": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "ClientScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstemci Betikleri"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Script"])},
    "NewSetScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Script"])},
    "EditSetScript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script Güncelle"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "ScriptCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script Kodu"])},
    "ScriptType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstemci Betikleri"])},
    "ScriptTypeNewRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kayıt Görüntüleme"])},
    "ScriptTypeEditRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncelleme"])},
    "ScriptTypeListRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste Kaydı Görüntüleme"])},
    "ScriptTypeDetailRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Detay Görüntüleme"])},
    "ScriptTypeKanban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Görüntüleme"])},
    "ScriptTypeCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Görüntüleme"])},
    "ScriptTypeMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita Görüntüleme"])},
    "ScriptTypeTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağaç Görüntüleme"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "Fields": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Alan"])},
    "NewField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Alan"])},
    "EditField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Güncelle"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "HelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım Metni"])},
    "DefaultValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Değer"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzunluk"])},
    "DecimalPlaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondalık Kısım"])},
    "IsCalculateOnClientChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Değiştiğinde Hesaplama Güncellensin mi?"])},
    "CalculateTriggerField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplamayı Tetikleyen Alanlar"])},
    "IsClientCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama İstemci Tarafında Yapılsın mı?"])},
    "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu"])},
    "IsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu Alan"])},
    "IsUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özgün Veri"])},
    "IsEditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenebilir"])},
    "IsCheckUnchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer Değişmediyse Unique Alan Kontrolü Yapılmasın"])},
    "IsUniqueCheckClientCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstemcide Özgünlük Kontrol Edilsin Mi?"])},
    "IsThousandSeparator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binlik Ayıraç olsun"])},
    "Checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşaretli"])},
    "Unchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşaretsiz"])},
    "ZeroIsUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Sıfır limitsizdir)"])},
    "Max450Character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum 450 karakter"])},
    "MultipleSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu Seçim"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SelectList"])},
    "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı"])},
    "Document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doküman"])},
    "FieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Tipi"])},
    "ImageSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resim Seçimi"])},
    "OnylViewOnDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece Detay Sayfasında Görüntüleyebilsin"])},
    "OnlyViewOnModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece Açılır Pencerede Görüntüleyebilsin"])},
    "OnlyViewOnDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece İndirince Görüntüleyebilsin"])},
    "IsShortAlphabetically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetik Olarak Sırala"])},
    "SelectListItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listelenecek Öğeleri Yazınız"])},
    "AddSelecListItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste Elemanlarını Seçiniz"])},
    "IsControllingFilterAnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol 'VE' mantıksal işlemi ile mi filtreleniyor?"])},
    "IsRadio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radyo Düğmesi"])},
    "SelectListSelectedItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili Gelen Değer veya Değerler"])},
    "IsParentPredefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili bir alan ile mi kontrol ediliyor?"])},
    "IsPlusButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Ekleme Düğmesi Etkin"])},
    "IsViewingFilterButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif filtre Düğmesini Görüntüle"])},
    "IsSplitSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parçalı Arama"])},
    "IsLookupFieldGroupWanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek alanlar özelleştirilecek mi?"])},
    "IsRelationTableCreatedOnReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Veri Tabanında İlişki Tablosu Oluşsun"])},
    "OrganizationalUnitShowColumns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütünların Gösterimi"])},
    "LookupFieldPublicIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Nesne Alanları"])},
    "UserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu"])},
    "PermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "AllUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Kullanıcılar"])},
    "UseroftheUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubuna Ait Kişiler"])},
    "UsersofthePermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubuna Ait Kullanıcılar"])},
    "ChildrenGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Gruplar"])},
    "FilterFromField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlardan Filtrele"])},
    "FilterFromGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verilerden Filtrele"])},
    "AllDescendantsGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün Alt Gruplar"])},
    "IncludeItself": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kendisi Dahil"])},
    "Predefinedlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden tanımlanmış listeler"])},
    "TurkeyCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehirler (Türkiye)"])},
    "TurkeyCounties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçeler (Türkiye)"])},
    "TurkeyTowns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semtler (Türkiye)"])},
    "TurkeyDistricts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mahalleler (Türkiye)"])},
    "TurkeyPostalCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta Kodları (Türkiye)"])},
    "Currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Birimleri"])},
    "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
    "CountriesInTurkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe Ülkeler"])},
    "CountriesInEnglish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce Ülkeler"])},
    "IsEncrypted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifrele"])},
    "EncryptedType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreleme Tipi"])},
    "Regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regex"])},
    "ReadMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Maskesi"])},
    "AllUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün Gruplar"])},
    "IsUpdateWholeRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün Kayıtları Güncelle"])},
    "AggregateFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplama Fonksiyonu"])},
    "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topla"])},
    "Avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama"])},
    "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say"])},
    "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks"])},
    "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "IsTableRollup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo İçin Hesaplama Fonksiyonu"])},
    "ObjectsTableFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Tablo Alanları"])},
    "TableFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo Alanları"])},
    "LookupObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Nesne"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Ayarlar"])},
    "OrgUnitShowColumForName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "OrgUnitShowColumnForSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "OrgUnitShowColumnForEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "ControlingRelationField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol İlişki Alanı"])},
    "ControlingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol Alanı"])},
    "EncryptedCreditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreli Kredi Kart"])},
    "EncryptedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreli E-posta"])},
    "EncryptedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreli Parola"])},
    "RollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])},
    "IsCalculatedOnlyOnInsert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sadece yeni kayıt sırasında mı hesaplansın?"])},
    "IsCalculatedOnlyOnInsertUnCheckedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan değer her güncelleme istediğinde tekrar hesaplanacaktır. Emin misiniz?"])},
    "IsAutoIdTextBasedIncrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin Tabanlı Artış"])},
    "AutoIdIncrementField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "Passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
    "FormulaIsEditableLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellemede Kayıtta değer var ise hesaplama"])},
    "NotFormulaIsEditableLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme Sayfalarında Değer Girilemez"])},
    "FieldValueCopyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangi alanın değerini kopyalamak istersiniz?"])},
    "SetSelectedItemByFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili değeri formül ile belirle"])},
    "IsDataReadFromMainTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veriler tablodan canlı okunsun mu?"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "FieldDiagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Diyagramı"])}
  },
  "PageLayouts": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sayfa Tasarımı"])},
    "NewPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sayfa Tasarımı"])},
    "EditPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı Güncelle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa Tasarımı"])},
    "FormTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Şablonları"])},
    "DeleteButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme"])},
    "EditButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "HistoryButtonActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş"])},
    "IsCellEditingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hücrede Güncelleme Etkin"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "PageDesignEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa tasarımında alan bulunmuyor. Lütfen kontrol ediniz."])},
    "IsMobileLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Sayfa Tasarımı"])},
    "DependentPageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı Olduğu Sayfa Tasarımı"])},
    "MobileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Ayarları"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "DependentPageLayoutName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlı Olduğu Sayfa Tasarımı"])},
    "IsApiModelEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Model Button Etkin"])},
    "LayoutCopyTemplateButon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı Şabolununu Kopyala (Alan, Buton vb. elemanları içermez)"])},
    "LayoutPasteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarımı Yapıştır. Mevcut Tasarım Silinecektir"])},
    "LayoutCopyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımını Kopyala. Aynı Objede Yeni Sayfa Tasarımı Oluşacaktır"])},
    "CopyApiRequestForLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API İçin İstek Kopyala"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "CalendarViews": {
    "NewCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Takvim"])},
    "EditCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Güncelle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Tarihi"])},
    "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş Tarihi"])},
    "TitleObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Objesi"])},
    "TimeLineField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvimde Görünecek ilk alan"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "IsInsertOnCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Üzerinden Yeni Kayıt Ekleme"])},
    "IsEditableOnCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Üzerinden Güncelleme Yapabilme"])},
    "Views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek Takvim Türleri"])},
    "DefaultView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan Takvim Görüntüsü"])},
    "MinTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat Aralığı Başlangıç Saati"])},
    "MaxTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat Aralığı Bitiş Saati"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "LightboxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılır Pencere Oranı"])},
    "DataFetchSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Alma Boyutu"])},
    "ColorField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk Kodu Alanı"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "TreeViews": {
    "NewTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Ağaç Görünümü"])},
    "EditTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağaç Görünümü Güncelle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "HeaderField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Başlık"])},
    "SubObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Obje"])},
    "LayerViewField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katman Görünüm Alanı"])},
    "ChildField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Alan"])},
    "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
    "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Rengi"])},
    "ChildIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt İkon"])},
    "ChildIconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt İkon Rengi"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "LookupRelationWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu ilişkili nesnenin ait olduğu özel nesnenin herhangi bir ilişkili nesnesi bulunmamaktadır."])},
    "LayerViewWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir ağaç dalı ekleyebilmek için önce alanları doldurunuz."])}
  },
  "DependencyFields": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "ControllingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol Alanı"])},
    "ControllingFieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol Alan Adı"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Alan İlişkilendirme"])},
    "NewDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Alan İlişkilendirme"])},
    "DetailDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan İlişkilendirme Detay"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
    "SelectTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo Seçiniz"])},
    "EditDependencyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan İlişkilendirme Güncelle"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "DependentField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkilendirilen Alan"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Boyutu"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "PleaseRelateTheFieldsWithEachOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen alanları birbiriyle ilişkilendirin"])}
  },
  "MessageTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "TemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonun Tipi"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "TemplateDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Dizaynı"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesaj Şablonu"])},
    "NewMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesaj Şablonu"])},
    "DetailMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonu Detay"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
    "EditMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonu Güncelle"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Boyutu"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "SystemMessageTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "TemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablonun Tipi"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "TemplateDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Dizaynı"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesaj Şablonu"])},
    "NewMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Mesaj Şablonu"])},
    "DetailMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonu Detay"])},
    "General": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
    "EditMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonu Güncelle"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Boyutu"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "TemplateLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Dili"])},
    "TemplateKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Türü"])},
    "BulkImports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Veri Yükleme"])},
    "ResetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre Sıfırlama"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dışarı Aktar"])},
    "SendTestMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Mail"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])}
  },
  "FormTemplates": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "OutputType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Çıktı Tipi"])},
    "File": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Form Şablonu"])},
    "NewFormTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Form Şablonu"])},
    "EditFormTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Şablonu Güncelle"])},
    "PageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Boyutu"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelle"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "BrowseOrDragandDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gözat veya Sürükle & Bırak"])},
    "IsGetFromFilenameFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Adı Alanlarından Gelir"])},
    "IsSetEmptyIfValueNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer bulunamazsa boşluk atansın mi?"])},
    "IsPermissionApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin geçerli mi?"])},
    "IsValidationApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon Uygulansın Mı?"])},
    "ValidationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon için kayıt bazlı validasyon uygulanır. Bu validasyona göre kullanıcılar kayıt için bu form şablonunu görür."])},
    "FileNameFieldNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Adı Alan Adları"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporları"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Raporlar"])},
    "ReportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Tipi"])},
    "IsPreviewEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizlemeyi Etkinleştir"])},
    "FirstParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlk Parametre"])},
    "SecondParameter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkinci Parametre"])}
  },
  "ReportTypes": {
    "WordTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word Şablonu"])},
    "XtraReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Rapor"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "CustomButtons": {
    "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butonlar"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Buton"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton Güncelle"])},
    "ButtonType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton Tipi"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "BusinessRuleButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı"])},
    "DirectForwardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkt Yönlendirme"])},
    "CustomForwardButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Yönlendirme"])},
    "DirectForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkt Yönlendirme"])},
    "FormTemplateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Şablonu"])},
    "NewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sekme"])},
    "LightBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılır Pencere"])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "StaticLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statik Link"])},
    "ForwardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönlendirme Tipi"])},
    "LightboxRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılır Pencere Oranı"])},
    "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
    "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Rengi"])},
    "IsIconViewEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkonu Etkinleştir"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "BusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı"])},
    "SendDefaultValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni kayıt Sayfasına Varsayılan Değer Gönder"])},
    "SendDefaultValueObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "IsValidationApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon Uygulansın Mı?"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon"])},
    "ValidationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton için kayıt bazlı validasyon uygulanır. Bu validasyona göre kullanıcılar işlem yapabilir."])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "IsShowApprovalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay Mesajı Görüntülensin mi?"])},
    "ApprovalMessageContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay Mesajı"])},
    "IsPageRefreshAfterProcessDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Tamamlandığında Sayfa Yenilensin Mi?"])},
    "AppearanceSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görünüm Ayarları"])},
    "ShowAtDetailPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Sayfasında Görüntülensin"])},
    "ShowAtNewPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kayıt Sayfasında Görüntülensin "])},
    "ShowAtEditPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme Sayfasında Görüntülensin"])}
  },
  "Menus": {
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menüler"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Sayfası"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Düzenle"])},
    "IsSideBarActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan Menü Aktif"])},
    "IsDocumentActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doküman Butonu Aktif"])},
    "IsChatActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Butonu Aktif"])},
    "IsDelegationActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegasyon Butonu Aktif"])},
    "IsSystemNotificationActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Bildirimi Butonu Aktif"])},
    "MenuItems": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Elemanları"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
      "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Sayfası"])},
      "MenuTree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu Ağacı"])},
      "NewMenuItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü Öğesi"])},
      "NewMenuSubItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü Alt Öğesi"])},
      "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Öğesi Güncelle"])},
      "EditSubItemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Alt Öğesi Güncelle"])},
      "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Öğesi Detay"])},
      "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü Öğesi"])},
      "NewSubItemTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Menü Alt Öğesi"])},
      "IsOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sekme Açma"])},
      "IsSidebarItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan Menü Öğesi"])},
      "IconColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Rengi"])},
      "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
      "SidebarItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan Menü Öğeleri"])},
      "NavbarItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Menü Öğeleri"])},
      "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
      "IsCustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Yolu Ekle"])},
      "IsSidebarItemIconRequiredMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yan Menü Öğesi ekleme işleminde ikon seçimi zorunludur. Lütfen kontrol ediniz."])}
    }
  },
  "ProjectParameters": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Parametreleri"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Proje Parametresi"])},
    "NewProjectParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Proje Parametresi"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Proje Parametresi"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Parametresi Güncelleme"])}
  },
  "Projects": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Proje Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Proje"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Düzenle"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma id"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projeler"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Adı"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adı"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "CompanyAdminEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Yönetici Email"])},
    "RelatedPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi"])},
    "RecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Sayısı"])},
    "UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Sayısı"])},
    "ActiveUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Kullanıcı Sayısı"])},
    "CreateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma Tarihi"])},
    "DateOfContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Tarihi"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Detay"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "Configurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurasyonlar"])},
    "UsageInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Bilgileri"])},
    "ContractInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Bilgileri"])},
    "FormulaErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Hataları"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "Instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "PasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola Tekrar"])},
    "BlankProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boş Proje"])},
    "Clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopya"])},
    "ProjectInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Bilgileri"])},
    "DatabaseInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı Bilgileri"])},
    "UserInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Bilgileri"])},
    "TaxOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Dairesi"])},
    "TaxNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Numarası"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "County": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçe"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "ContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi"])},
    "ContactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi Email"])},
    "ContactPersonTel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi Tel"])},
    "ContractStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme Başlangıç Tarihi"])},
    "UnitPriceOfUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık Kullanıcı Tutarı"])},
    "StartUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşmedeki Kullanıcı Sayısı"])},
    "CurrentUserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncel Kullanıcı Sayısı"])},
    "IsIpControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girişte IP Kontrolü Yapılsın"])},
    "IsFormulaErrorLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Logları Açık"])},
    "IsRecordLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlar Loglansın (Create,Update,Delete)"])},
    "IsRecordViewLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Görüntüleme Loglansın"])},
    "IsLabelMultiLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu Dil Desteği Açık"])},
    "IsApiLogOpen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api İşlemleri Loglansın"])},
    "IsMessageOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Uyarı Mesajı Açık"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Uyarı Mesajı"])},
    "IsTwoFactorAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çift Katmanlı Yetkilendirme Açık"])},
    "ProjectPassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje pasif durumdadır. Giriş işlemi gerçekleştirilemez. Danışmanınız ile iletişime geçebilirsiniz"])}
  },
  "DatabaseSettings": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı Ayarları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Veritabanı Ayarı Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Veritabanı Ayarı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı Ayarı Düzenle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "DbType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "InstanceIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İp Adresi"])},
    "SchemaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şema İsmi"])},
    "CnnStringWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Noktası"])},
    "CnnStringRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Noktası Okuma"])},
    "Postgresql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgresql"])},
    "Sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MsSql"])}
  },
  "RecordLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Logları"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "ActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksiyon Tipi"])},
    "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç Tarihi"])},
    "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş Tarihi"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
    "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
    "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "RecordPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Benzersiz Id"])},
    "Record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "DifferenceBetweenTwoRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İki Kayıt Arasındaki Fark"])},
    "FieldServiceCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturuldu"])},
    "FieldServiceUpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncellendi"])},
    "FieldServiceDeleteRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Silindi"])},
    "FieldServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Güncellendi"])},
    "KanbanViewServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanban Alan Değeri Güncellendi"])},
    "CalendarViewServiceUpdateFieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takvim Alan Değeri Güncellendi"])},
    "FieldServiceGetRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Görüntülendi"])},
    "FieldServiceExportRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Dışarı Aktarıldı"])},
    "FieldServiceBulkUpdateRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Toplu Güncellendi"])},
    "UserStatusUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Durumu Güncellendi"])},
    "FieldServiceBulkImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt İçeri Aktarıldı"])},
    "LogDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Detayı"])},
    "LogCompare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtları Karşılaştır"])},
    "LogIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log dosyasının içeriği bulunamadı."])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Adı"])},
    "NewValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Değer"])},
    "OldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski Değer"])},
    "InvertTableColors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablonun Renklerini Tersine Çevir"])}
  },
  "FormulaErrorLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Hatası Logları"])},
    "ErrorLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Logları"])},
    "ExceptionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Mesajı"])},
    "Formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])}
  },
  "ImportBulkRecordData": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Veri Yükleme"])},
    "NewBulkImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toplu Veri Yükleme"])},
    "DataCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Adeti"])},
    "NewImportBulkRecordData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Toplu Veri Yükleme"])},
    "Insert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kayıt"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme"])},
    "RecordUploadType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Yükleme Türü"])},
    "DoNotTriggerBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" İş Kuralları Tetiklenmesin"])},
    "DoNotCalculateRollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollup Alanları Tetiklenmesin"])},
    "DoNotCalculateFormulaFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Formül Alanları Hesaplanmasın"])},
    "InstantStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Anında Başlasın"])},
    "StartAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Başlangıç Zamanı"])},
    "ImportFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Dosya Yükle (xls,xlsx)"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Mail Adresi"])},
    "ReloadMapping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleştirilen verileriniz kaybolacaktır. Emin misiniz?"])},
    "MappingTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanları Eşleştir"])},
    "ExcelField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel Alanı"])},
    "ObjectField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Alanı"])},
    "ReferenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referans Tipi"])},
    "ReferenceField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referans Alanı"])},
    "PleaseSelectReferenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen referans tipi seçiniz"])},
    "PleaseSelectReferenceField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen referans alanı seçiniz"])},
    "ReferenceTypeForName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "ReferenceTypeForField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
    "NoUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzer eşleştirmeyi daha önce eklediniz. Lütfen eşleştirme tablosunu kontrol ediniz"])},
    "NoUniqueItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benzer eşleştirmeler tespit edildi. Lütfen eşleştirme tablosunu kontrol ediniz"])},
    "NoMatchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az bir tane excel alanı ile özel nesne alanını eşleştiriniz"])},
    "CompletedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlanma Tarihi"])},
    "UploadedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüklenen Dosya"])},
    "ProcessedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlenen Dosya"])},
    "Monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu Veri Yükleme İzleme"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma Tarihi"])},
    "ExpireAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Logunun Silineceği Tarih"])},
    "FetchedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getirilme Tarihi"])},
    "StateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statü"])},
    "StateReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statü Açıklaması"])},
    "StateData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statü Verisi"])},
    "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekliyor"])},
    "Processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam Ediyor"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamamlandı"])},
    "CompletedWithErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatalarla Tamamlandı "])},
    "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız"])},
    "Succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
    "Deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silindi"])}
  },
  "FieldErrors": {
    "UrlNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir url giriniz"])},
    "EmailNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen geçerli bir email adresi giriniz"])},
    "TextNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bu alanı doldurun"])},
    "FileNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen dosya seçimi yapınız"])},
    "ImageNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen resim seçimi yapınız"])},
    "NoGuidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen Kayıt Benzersiz Id alanını istenilen biçimde giriniz. (00000000-0000-0000-0000-000000000000)"])},
    "FilterItemsNotChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen filtreleme seçeneklerini değiştirip tekrar deneyiniz."])},
    "UploadFileNotSupportedForWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen word dökümanı ekleyiniz"])},
    "UploadFileNotSupportedForExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen excel dökümanı ekleyiniz"])},
    "ExcelColumnsEmptyOrWrongDataType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel kolonları boş veya istenmeyen veri tipi bulunduruyor. Lütfen kontrol edip tekrar deneyiniz"])},
    "FillInTheRequiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen zorunlu alanları doldurunuz"])},
    "NoOrderChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre uygulanmış liste kayıtları üzerinde sıra değişikliği yapılmamaktadır. Lütfen arama kriterini temizleyip tekrar deneyiniz."])}
  },
  "DataSummary": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Veri Özeti Ekle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Özeti"])}
  },
  "Dashboard": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Rapor Paneli Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Rapor Paneli"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Paneli Düzenle"])},
    "EditHtmlTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Paneli Html&Script Düzenle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Paneli"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
  },
  "CustomPage": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Sayfa Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Sayfa"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa Düzenle"])},
    "EditHtmlTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa Html&Script&Css Düzenle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS"])},
    "IsSwitchCustomPagesEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfalar Arası Geçiş Etkin"])},
    "IsMenuHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizli Menü"])},
    "IsHideOnDropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropdown'da Gizle"])}
  },
  "Holidays": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Tatil Günü Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Tatil Günü"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatil Günü Düzenle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatil Günleri"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün"])},
    "IsHalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarım gün"])}
  },
  "CustomObjectDeleteRules": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Nesne Silme Kuralı Ekle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Silme Kuralları"])}
  },
  "SystemLogs": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Logları"])}
  },
  "SystemLoginInformations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Giriş Bilgileri"])}
  },
  "NotificationSettings": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Ayarları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Bildirim Ayarı Ekle"])},
    "SendMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Maili Gönder"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Bildirim Ayarı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Ayarı Düzenle"])},
    "DisplayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek İsim"])},
    "NotifierType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Türü"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "Domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "IsExchangeServer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunucu Değişimi"])},
    "SenderEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP E-Posta"])},
    "Port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Port"])},
    "Host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP Host"])},
    "SSL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMTP güvenli bağlantı ile mi çalışıyor"])},
    "TestEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test maili gönder"])},
    "TestSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sms Gönder Butonu"])},
    "SMSBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Bakiye"])},
    "IsBeingUsedForSystemEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Emaili için kullanılsın"])}
  },
  "Users": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcılar"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kullanıcı Ekle"])},
    "DeletedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silinmiş Kullanıcılar"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kullanıcı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Düzenle"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Detayı"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Bilgiler"])},
    "CustomSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer Bilgiler"])},
    "ChangeProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Resmini Değiştir"])},
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ünvan"])},
    "UserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı"])},
    "GSM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM No"])},
    "IdentityNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tc Kimlik Numarası"])},
    "Extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dahili"])},
    "Department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bölüm"])},
    "BirthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum Tarihi"])},
    "StartWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşe Başlama Tarihi"])},
    "DepartureWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrılma Tarihi"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menüler"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "PasswordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola Tekrar"])},
    "PermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grupları"])},
    "ActiveLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Dil"])},
    "TR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkçe"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngilizce"])},
    "UserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grupları"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılış Sayfası"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 3"])},
    "Level1Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Derece Yönetici"])},
    "Level2Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Derece Yönetici"])},
    "Level3Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Derece Yönetici"])},
    "Buddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekip Arkadaşı"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okuma"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SİL"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEÇMİŞ"])},
    "Objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesneler"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "PageDesigner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Ayarları"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrele"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İZİN"])},
    "PermissionGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Adı"])},
    "UserGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grup Adı"])},
    "MarkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini işaretle"])},
    "UnmarkAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm seçilenleri kaldır"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Okuma"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Okuma"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERİ AKTARIMI"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/K"])},
    "CacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek"])},
    "CacheDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfa <b class='badge badge-warning text-capitalize'><i class='bi bi-person-fill'></i> USER_NAME</b> kullanıcısının önbellek verisini göstermektedir. <br>Yetkiler kişisel ve gruplardan gelen (üst ilişkileri ile beraber) yetkilerin toplamı gösterilir. <br>Veri ile ilgili bir problem var ise ilişkili (yetki, menü gibi) verileri tekrar kaydedip 1 dakika sonra tekrar deneyiniz.<br> Problem devam eder ise <u>danışmanınız</u> ile iletişime geçiniz."])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetkiler"])},
    "ChildRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Eleman Sayısı"])},
    "Detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay"])},
    "PageDesigners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımları"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtreleri"])},
    "CacheReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek Verisini Yenile"])},
    "IsApiUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Kullanıcısı"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfalar"])},
    "CustomPageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa Adı"])},
    "DashboardReportOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra"])},
    "DashboardReportFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "DashboardReportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Adı"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporları"])},
    "XtraReportOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra"])},
    "XtraReportFormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "XtraReportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Adı"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Raporları"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif/Pasif"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "UndoDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme İşlemini Geri Al"])}
  },
  "UserGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grupları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kullanıcı Grubu Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kullanıcı Grubu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu Düzenle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu Açıklaması"])},
    "ParentUserGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Adı"])},
    "ParentUserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Kullanıcı Grubu"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Yönetici"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu Detayı"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif/Pasif"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "ParentUserGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Kullanıcı Grupları"])}
  },
  "PermissionGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grupları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Yetki Grubu Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Yetki Grubu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Düzenle"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Detayı"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Açıklaması"])},
    "ParentPermissionGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Adı"])},
    "ParentPermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Yetki Grubu"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Ayarları"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Menü"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Yönetici"])},
    "IsGetPermissionFromParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Gruptan Yetki Alsın"])},
    "CheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini İşaretle"])},
    "UnCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Seçilenleri Kaldır"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesneler"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtreleri"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GÖRÜNTÜLEME FİLTRESİ"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımları"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporları"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Raporları"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfalar"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/K"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okuma"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Okuma"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Okuma"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERİ AKTARIMI"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SİLME"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEÇMİŞ"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İZİN"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılış Sayfası"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif/Pasif"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "ParentPermissionGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Yetki Grupları"])}
  },
  "RestrictedPermissionGroups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısıtlı Yetki Grupları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kısıtlı Yetki Grubu Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Kısıtlı Yetki Grubu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısıtlı Yetki Grubu Düzenle"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kısıtlı Yetki Grubu Detayı"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu Açıklaması"])},
    "PermissionSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Ayarları"])},
    "ActiveMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Menü"])},
    "SpecificCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 1"])},
    "SpecificCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 2"])},
    "SpecificCode3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Kod 3"])},
    "GroupAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup Yönetici"])},
    "CheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini İşaretle"])},
    "UnCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Seçilenleri Kaldır"])},
    "CustomObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesneler"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])},
    "ViewFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtreleri"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GÖRÜNTÜLEME FİLTRESİ"])},
    "PageLayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımları"])},
    "PageLayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
    "DashboardReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporları"])},
    "XtraReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Web Raporları"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "CustomPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfalar"])},
    "CustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa"])},
    "AddRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E/K"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturma"])},
    "Read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okuma"])},
    "DetailRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay Okuma"])},
    "FileRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya Okuma"])},
    "DataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERİ AKTARIMI"])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncelleme"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SİLME"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEÇMİŞ"])},
    "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylem"])},
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
    "IsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İZİN"])},
    "WelcomeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açılış Sayfası"])},
    "PageFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Filtresi"])},
    "ActivePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif/Pasif"])},
    "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])}
  },
  "FormulaErrors": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Hataları"])}
  },
  "UsageCounts": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Sayıları"])}
  },
  "Configurations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyonlar"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Konfigürasyon Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Konfigürasyon"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyon Düzenle"])},
    "SetXrmKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXrm Anahtarı"])},
    "SetXrmDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXrm Açıklaması"])},
    "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtar"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "String": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["String"])},
    "Integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integer"])},
    "Bool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bool"])},
    "Encrypted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted"])},
    "TypeSetXRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM"])},
    "TypeCustom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Özel Konfigürasyonlar"])},
    "TypeAllSetxrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm SetXRM Konfigürasyonları"])},
    "TypeSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Konfigürasyonu"])},
    "TypeCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Konfigürasyon"])},
    "NewSetxrmConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetXRM Konfigürasyonu"])},
    "NewCustomConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Özel Konfigürasyon"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Id"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adı"])},
    "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "Decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal"])},
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "DateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DateTime"])},
    "Boolean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean"])}
  },
  "Components": {
    "LanguageFlagSelectBox": {
      "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
      "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şili"])},
      "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çin"])},
      "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolombiya"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almanya"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İngiltere"])},
      "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonya"])},
      "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
      "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
      "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İspanya"])},
      "tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türkiye"])},
      "united-states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amerika"])},
      "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransa"])}
    },
    "QueryBuilder": {
      "CriteriaNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "CriteriaField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
      "CriteriaOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatör"])},
      "CriteriaValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer"])},
      "CriteriaExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "NoCriteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henüz kriter eklemediniz"])},
      "AsFormula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül"])}
    },
    "CronGenerator": {
      "Minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dakika"])},
      "Hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatlik"])},
      "Daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Günlük"])},
      "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftalık"])},
      "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aylık"])},
      "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıllık"])},
      "Every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her"])},
      "StartsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç"])},
      "EveryWeekDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta İçi Her Gün"])},
      "StartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç ​​Saati"])},
      "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazartesi"])},
      "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salı"])},
      "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çarşamba"])},
      "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perşembe"])},
      "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuma"])},
      "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumartesi"])},
      "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazar"])},
      "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün"])},
      "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])},
      "First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birinci"])},
      "Second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkinci"])},
      "Third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üçüncü"])},
      "Fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dördüncü"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron İş Kuralı Oluşturma"])},
      "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocak"])},
      "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şubat"])},
      "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mart"])},
      "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nisan"])},
      "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayıs"])},
      "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haziran"])},
      "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temmuz"])},
      "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağustos"])},
      "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül"])},
      "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekim"])},
      "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasım"])},
      "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])},
      "TimeTriggerExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman Tetikleyicisi"])},
      "Hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saat"])},
      "Generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])},
      "CronTabFieldsValidationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron ifadenizin bazı alanları eksik! Lütfen kontrol edin."])}
    },
    "CheckInternetConnection": {
      "YouAreOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çevrimdışısınız"])},
      "BackOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden çevrimiçi"])}
    },
    "DeleteModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Uyarısı"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı silmek istediğinizden emin misiniz?"])},
      "DeleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
      "DeleteClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
      "IConfirmDeletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmeyi Onaylıyorum"])}
    },
    "TranslateModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tercüme Yapma Ekranı"])},
      "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
      "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "Translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])}
    },
    "404": {
      "GotoDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfaya Git"])},
      "GotoCustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfaya Git"])},
      "Oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata!"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfa artık yok gibi görünüyor."])}
    },
    "PermissionNone": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İzin Gerekli"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu Sayfaya Erişim İzniniz Bulunmamaktadır"])}
    },
    "PageUnderConstruction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Yapım Aşamasında"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfa henüz kullanıma açık değildir."])}
    },
    "IconPicker": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Seçici"])},
      "AllIcons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bütün İkonlar"])},
      "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adına göre filtrele..."])},
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
      "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])}
    },
    "BlogList": {
      "ReadDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detayını Oku"])},
      "WhatIsSetXRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM Nedir ve SetXRM’in Avantajları"])},
      "SetXRMWorkflowManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetXRM İş Akış Yönetimi"])},
      "WhatIsDashboardReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Raporlama Nedir? Özellikleri Nelerdir?"])},
      "WhatIsCustomPageReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa Nedir? Özellikleri Nelerdir?"])},
      "AllOurArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Yazılarımız"])}
    },
    "JsonViewerModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json Görüntüleyici"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
    },
    "BpmModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bpm Dizayn"])}
    },
    "PermissionAlertModal": {
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydedilmeyen veriler kaybolacaktır emin misiniz?"])}
    },
    "FormulaEditorModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Editörü"])},
      "OpenFormulaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Editörünü Aç"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
      "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
      "Functions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyonlar"])},
      "Operators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatörler"])},
      "DateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih ve Saat"])},
      "Logical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantıksal"])},
      "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel"])},
      "Math": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metamatiksel"])},
      "String": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı"])},
      "Spatial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita"])},
      "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
      "SearchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan ara..."])},
      "SearchFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon ara..."])},
      "SearchOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatör ara..."])},
      "ActiveThemeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editörün teması <a class='THEME_ID border-dark border me-1 ms-1'></a> olarak ayarlandı"])}
    },
    "FieldDiagramModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diyagramı"])}
    },
    "PageDesignerModal": {
      "ModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı"])},
      "DesignItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dizayn Elemanları"])},
      "CustomObjectItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne Elemanları"])},
      "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan, nesne ilişkilendirmesi veya buton ara..."])},
      "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlar"])},
      "ObjectRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirmeleri"])},
      "Buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butonlar"])},
      "CustomObjectItemNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel nesne elemanları bulunamadı."])},
      "Preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önizle"])},
      "SaveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikleri Kaydet"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
      "EditItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleman Düzenle"])},
      "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
      "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirmesi"])},
      "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton"])},
      "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
      "FieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Tipi"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "ColumnRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun Aralığı"])},
      "IsItTableSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo mu?"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
      "Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sınıf"])},
      "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj"])},
      "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk"])},
      "Collapsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapalı Gelsin"])},
      "Size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boyut"])},
      "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
      "Justified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşit Olarak Ana Eksene Yay"])},
      "IsNextStepRequiredFieldValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonraki Adıma Geçmek için Zorunlu Alan Doğrulaması Yap"])},
      "ChildItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Öğeler"])},
      "FontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı Rengi"])},
      "TableTitleFontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık Yazı Rengi"])},
      "TableFontColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablo Yazı Rengi"])},
      "LinearGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk Geçişi"])},
      "ResetLinearGradient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk Geçişini Sıfırla"])},
      "IsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorunlu Alan mı?"])},
      "IsDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilitli mi?"])},
      "IsHidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizli mi?"])},
      "SeeDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dökümanı İncele"])},
      "ColumRangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'0' olarak ayarlarsanız, diğer sütunlara göre yayılacaktır."])},
      "ColorDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğenizin rengini ayarlayın."])},
      "ContainerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapsayıcı görünümünüzü ayarlayın."])},
      "ColumnDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun görünümünüzü ayarlayın."])},
      "HowItWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasıl Çalışır"])},
      "GridOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grid Ayarları"])},
      "BorderDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenarlığın sınıfını ayarlayın. Örneğin: rounded border-5"])},
      "IconDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bir simge değerini kopyalayıp yapıştırın."])},
      "Icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkonlar"])},
      "SetYourUtilitiesOfTheItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öğenin yardımcı programlarını ayarlayın."])},
      "LabelClassNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiket Sınıf Adları"])},
      "ValueClassNames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer Sınıf Adları"])},
      "AlertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basit bir birincil uyarı - kontrol edin!"])},
      "DeleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELEMENT içinde ELEMENT_COUNT alt öğe mevcut. Emin misiniz?"])},
      "Element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleman"])},
      "DesignArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım Alanı"])},
      "CustomClasses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sınıflar"])}
    },
    "BusinessRuleActionViewModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyonları"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
    },
    "NotificationSettingTestModal": {
      "EmailTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Maili Gönder"])},
      "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta Adresi"])},
      "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
      "SMSTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test SMS Gönder"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Numarası"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
    },
    "NotificationSMSBalanceModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Bakiye"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
    },
    "JsonPasteModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Json Yapıştırma Modal"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydederseniz mevcut veri yapıştırılan veri ile değiştirilecektir. Emin misiniz?"])}
    },
    "TranslationModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çoklu Dil Desteği Çeviri Ekranı"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
      "TranslationKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri Anahtarı"])},
      "TranslationValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri Değeri"])},
      "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil"])},
      "TranslationKeyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri Anahtarı Tipi"])}
    },
    "PasswordChangeModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre Değiştir"])},
      "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b class='badge badge-warning text-capitalize'><i class='bi bi-person-fill'></i> USER_NAME</b> adlı kullanıcının şifresini güncelleyeceksiniz. Emin misiniz?"])}
    },
    "AreYouSureModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emin misiniz"])},
      "BulkImportCancelTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal Et"])},
      "BulkImportCancelWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplu veri yükleme işlemini iptal edeceksiniz. Emin misiniz?"])},
      "UndoDeleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme İşlemini Geri Al"])},
      "UndoDeleteWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme işlemini geri almak üzeresiniz. Emin misiniz?"])},
      "ClearCompanyCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek Verilerini Temizle"])},
      "ResetCompanyCacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek Verilerini Yenile"])},
      "CompanyClearCacheWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek verilerini temizlemek üzeresiniz. Emin misiniz?"])},
      "CompanyResetCacheWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek verilerini yenilemek üzeresiniz. Emin misiniz?"])},
      "IConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylıyorum"])},
      "ResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])},
      "ClearButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
      "CancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal Et"])},
      "UndoDeleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme İşlemini Geri Al"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])}
    },
    "FunctionDebugModal": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Ayıklama"])},
      "Execute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalıştır"])},
      "Output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıktı"])},
      "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
    },
    "LostWave": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne yazık ki, bir şeyler ters gitti"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğinizi yerine getiremiyoruz. Bize bilgi verildiğinden ve sorunla ilgilenmekte olduğumuzdan emin olabilirsiniz. Lütfen tarayıcınızı yenileyin. Hata devam ederse, lütfen destek ekibimizle iletişime geçin."])},
      "DescriptionWithInternalErrorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsteğinizi yerine getiremiyoruz. <br>Aşağıdaki hata kodunu belirterek destek ekibimizle iletişime geçebilirsiniz."])}
    }
  },
  "Buttons": {
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "SaveAndNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet ve Yeni Oluştur"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temizle"])},
    "Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydol"])},
    "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
    "ForgotYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifremi Unuttum"])},
    "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre Güncelle"])},
    "PleaseWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bekleyiniz..."])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "RecordView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı Görüntüle"])},
    "TryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden Dene"])},
    "Refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "ReturnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfaya Git"])},
    "SupportTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek Ekibi"])}
  },
  "Messages": {
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz başarıyla gerçekleşti."])},
    "ErrorMesage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu."])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşleminiz yapılıyor, lütfen bekleyiniz."])}
  },
  "Login": {
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Adresiniz"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanız"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen hesabınızla oturum açın ve kontrol paneline devam edin."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız yok mu?"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yap"])},
    "InvalidEmailOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail adresiniz veya parolanız geçersiz. <br /> Lütfen kontrol ediniz."])},
    "AuthenticationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş Yapılamıyor. <br> Lütfen sistem yöneticiniz ile iletişime geçiniz."])}
  },
  "Register": {
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Adresiniz"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanız"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hesap oluşturmak için lütfen kimlik bilgilerinizi girin."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaten hesabınız var mı?"])},
    "PasswordHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz en az 8 karakter uzunluğunda olmalıdır*"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydol"])}
  },
  "ForgotPassword": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifremi Unuttum"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Adresiniz"])},
    "AuthDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızın şifresini hatırlamıyorsanız şifre sıfırlama linki gönderebilir ve şifrenizi sıfırlayabilirsiniz."])},
    "AuthDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınız var mı?"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adresiniz sistemimizde kayıtlı ise şifre sıfırlama talimatlarını içeren bir e-mail <br><span class='text-primary text-decoration-underline'>EMAIL_ADDRESS</span> adresine gönderilecektir."])}
  },
  "PasswordChange": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifremi Değiştir"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harf, rakam ve simgelerin karışımından oluşan 8 veya daha fazla karakter kullanın."])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "ConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreni Onayla"])},
    "PasswordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz şifreler eşleşmiyor!"])},
    "PasswordLengthError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz en az 6 karakter olmalıdır!"])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre sıfırlama işleminiz başarıyla tamamlandı, Yeni şifreniz ile giriş yapabilirsiniz."])},
    "FormHiddenMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bağlantının kullanım limiti dolduğu için form otomatik olarak gizlendi."])},
    "PasswordRulesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreniz en az 8 karakter, bir büyük harf ve en az bir tane özel karakter içermelidir. Lütfen tekrar deneyin."])}
  },
  "DeletionRule": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Kuralı"])},
    "NewDeleteRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Silme Kuralı"])},
    "EditDeleteRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Kuralı Düzenle"])},
    "DeletionRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silme Kuralları"])},
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Silme Kuralı"])}
  },
  "BusinessProcess": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Süreci"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreçleri"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Süreci"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreci Düzenle"])}
  },
  "BusinessProcessDashboard": {
    "BusinessProcessCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreci"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı"])},
    "BusinessRuleActionCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksiyon"])},
    "CreateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluştur"])},
    "UpdateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncelle"])},
    "SendNotificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Gönder"])},
    "SendHTTPRequestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP İstek"])},
    "SendReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Gönder"])},
    "SendXtraReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Rapor Gönder"])},
    "SendDashboardCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Gönder"])},
    "BusinessRuleStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı İstatistikleri"])},
    "LastFailedJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son <b class='text-danger'>Başarısız</b> İstekler"])},
    "DeletionPeriodDaysForSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<i class='bi bi-info-circle-fill'></i> Başarılı İşlerin Silinme Periyodu: NUMBER gün"])}
  },
  "BusinessProcessCustomPage": {
    "BusinessProcessCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreci"])},
    "BusinessRuleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı"])},
    "BusinessRuleActionCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksiyon"])},
    "CreateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluştur"])},
    "UpdateRecordCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncelle"])},
    "SendNotificationCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Gönder"])},
    "SendHTTPRequestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP İstek"])},
    "SendReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Gönder"])},
    "SendXtraReportCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Rapor Gönder"])},
    "SendCustomPageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Sayfa Gönder"])},
    "BusinessRuleStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı İstatistikleri"])},
    "LastFailedJobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son <b class='text-danger'>Başarısız</b> İstekler"])},
    "DeletionPeriodDaysForSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<i class='bi bi-info-circle-fill'></i> Başarılı İşlerin Silinme Periyodu: NUMBER gün"])}
  },
  "BusinessRule": {
    "NewBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Kuralı"])},
    "EditBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Güncelle"])},
    "GeneralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Ayarlar"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralları"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Kuralı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Düzenle"])},
    "BusinessRuleTriggerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Tetikleyicisi"])},
    "RecordCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturulduğunda"])},
    "RecordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncellendiğinde"])},
    "RecordCreatedOrUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturulduğunda ya da Güncellendiğinde"])},
    "RecordDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Silindiğinde"])},
    "TimeTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman Tetikleyicisi ile"])},
    "UserTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Tetikleyicisi ile"])},
    "BusinessProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Süreci"])},
    "CriteriaFieldsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterleme yapılacak alan bulunamadı."])},
    "DuplicateCriteriaItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterleriniz de yinelenen öğeler mevcut. Lütfen kontrol ediniz."])},
    "MaxCriteriaItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum 11 tane kriter ekleyebilirsiniz. Lütfen kontrol ediniz."])},
    "EnterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değer giriniz"])},
    "PleaseAddTheFieldToDisplayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen görüntülenecek alan(lar)ı ekleyip tekrar deneyiniz."])},
    "ActiveButtons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif Butonlar"])},
    "CustomObjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne Adı"])},
    "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan Adı"])},
    "Operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata Mesajı"])},
    "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesajı Modal Olarak Göster"])},
    "Criterias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriterler"])},
    "CriteriaExpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "CriteriaExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kriter Açıklaması"])},
    "FirstRunIntervalInSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" İlk Çalışma Aralığı (sn)"])},
    "RepeatCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarlama Sayısı (0-sınırsız)"])},
    "RepeatIntervalInSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrarlama Periyotu (sn)"])},
    "IsExecuteForever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışma Süresi Sınırsız"])},
    "Sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topla"])},
    "Avarage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortalama"])},
    "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Say"])},
    "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks"])},
    "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "BusinessRuleActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyonları"])},
    "CacheTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cache"])},
    "CacheDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu sayfa <b class='badge badge-warning'><i class='bi bi-box'></i> CUSTOMOBJECT_NAME</b> objesinin önbellek verisini göstermektedir. <br> Seçilen objeye ait iş kuralları verileri alt aksiyonları ile birlikte görüntülenebilir. <br>Veri ile ilgili bir problem var ise ilişkili (yetki, menü gibi) verileri tekrar kaydedip 1 dakika sonra tekrar deneyiniz.<br> Problem devam eder ise <u>danışmanınız</u> ile iletişime geçiniz."])},
    "CacheReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önbellek Verisini Yenile"])},
    "AllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
    "Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validasyon"])},
    "IsNotShowResultToClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç Kullanıcıya Gözükmesin"])}
  },
  "BusinessRuleActions": {
    "BusinessRuleActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyon Tipi"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Kuralı Aksiyonu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyon Düzenle"])},
    "NewBusinessRuleAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni İş Kuralı Aksiyonu"])},
    "EditBusinessRuleAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Aksiyonu Güncelle"])},
    "BusinessAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Aksiyonu"])},
    "BusinessActionObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Aksiyon Objesi"])},
    "BusinessActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Aksiyon Tipi"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "IsForcingSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri Doğrulama Kurallarını Göz Ardı Et"])},
    "IsRunOnceForEachRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her Kayıt İçin Bir Kez Çalıştır"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
    "CreateRecordRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Record Request"])},
    "CreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluştur"])},
    "UpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncelle"])},
    "SendHTTPRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP İsteği Gönder"])},
    "SendNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Gönder"])},
    "SendDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Gönder"])},
    "SendCustomPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page Gönder"])},
    "SendReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Gönder"])},
    "SendXtraReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Rapor Gönder"])},
    "NewActionCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Action Create Record"])},
    "EditActionCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Action Create Record"])},
    "SelectObjectYouWantToCreateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturmak İstediğiniz Objeyi Seçiniz"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrele"])},
    "SelectObjectYouWantToUpdateRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Güncellenecek Nesneyi Seçiniz"])},
    "SelectRelatedLookupField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili Lookup Alanı Seçiniz"])},
    "NotificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Türü"])},
    "MessageTemplateType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şablon Tipi"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "SystemNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem Bildirimi"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Ayarları"])},
    "SelectMessageTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj Şablonu Seç"])},
    "SendByEmailOfUserWhoUpdatesRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncelleyen Kullanıcının E-Postası İle Gönderilsin mi?"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konu"])},
    "SelectTemplateForNotificationPlugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Eklentisi İçin Şablon Seçiniz"])},
    "SelectAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eki Seç"])},
    "ToWho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kime"])},
    "CC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC"])},
    "BCC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCC"])},
    "SourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak Tipi"])},
    "FromTheText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metinden"])},
    "FromTheOrganizationUnitsOfTheCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmaya Ait Organizasyon Biriminden"])},
    "FromTheFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alanlardan"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı"])},
    "UserGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Grubu"])},
    "PermissionGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yetki Grubu"])},
    "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak"])},
    "Method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metod"])},
    "Get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get"])},
    "Post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "Put": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put"])},
    "HTTPRequestAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP İstek Adresi"])},
    "HTTPHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTPHeader"])},
    "HTTPRequestContentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Request Content Type"])},
    "AllRecordAsDto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AllRecordAsDto"])},
    "SelectedRecordValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SelectedRecordValues"])},
    "Custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "HTTPContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Content"])},
    "DoNotTriggerBusinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralını Tetiklenmesin"])},
    "DoNotCalculateFormulaFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formüle Alanlar Hesaplanmasın"])},
    "DoNotCalculateRollupFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplama alanları Hesaplanmasın"])},
    "AreYouSureYouWantToDeletetheRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydı silmek istediğinizden emin misiniz?"])},
    "IsResponseReturnToClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç Kullanıcıya Görünsün"])},
    "IsSynchAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksiyon Senkronize Çalışsın"])},
    "Related": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlişkili"])},
    "FirstManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Manager"])},
    "SecondManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second Manager"])},
    "ThirdManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Manager"])},
    "Teammate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekip Arkadaşı"])},
    "IsRunSeparatelyForEachUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Her kullanıcı için ayrı çalıştır"])},
    "IsNotSendEmailIfThereIsNoRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt yok ise e-mail gönderme"])},
    "EmailAdressInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta Adresi Bilgisi"])},
    "DashboardId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Id"])},
    "CustomPageId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Page Id"])},
    "ReportExportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Çıktı Tipi"])},
    "XtraReportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xtra Rapor Id"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "GetOtherFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer Alanları Getir"])},
    "ResponseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıt(Response) metni"])},
    "DownloadResponseAsDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanıtı(Response) Belge Olarak İndir"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])}
  },
  "BusinessRuleTriggerTypes": {
    "RecordCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturulduğunda"])},
    "RecordCreatedOrUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Oluşturulduğunda ya da Güncellendiğinde"])},
    "RecordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Güncellendiğinde"])},
    "RecordDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt Silindiğinde"])},
    "TimeTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaman Tetikleyicisi İle"])},
    "UserTriggered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Tetikleyicisi İle"])}
  },
  "ReportExportTypes": {
    "Pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pdf"])},
    "Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel"])},
    "Word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word"])},
    "Csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv"])},
    "ExcelXLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel(xls)"])}
  },
  "SetCodeFunctions": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Fonksiyonu"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Fonksiyonu Güncelle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Fonksiyonları"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Fonksiyonu"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Fonksiyonu Güncelle"])},
    "FunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon Kodu"])},
    "FunctionDefinition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon Tanımı"])},
    "OutputModelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OutputModel"])},
    "InputModelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InputModel"])},
    "InputModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" model"])},
    "ClearSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçimi Temizle"])},
    "OutputModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output Model Kodu"])},
    "InputModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Model Kodu"])},
    "RecordIsAvailableOnTheSystemMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kayıt şu an sistem üzerinde var olan bir kayıttır. Güncelleme işlemi anında yansıyacaktır."])},
    "RedirectUpdatePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modeli Güncelle Sayfasında Aç"])},
    "ModelUpdateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model başarıyla güncellendi"])},
    "ExecutableFunctionCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalıştırılabilir Fonksiyon Kodu"])}
  },
  "SetModel": {
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Modeli"])},
    "EditButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Modelini Güncelle"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Modelleri"])},
    "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Modeli"])},
    "ModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Kodu"])},
    "PickInputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon için Input Model Seçiniz"])},
    "PickOutputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon için Output Model Seçiniz"])},
    "PickFromCurrentModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Modellerden Seç"])},
    "CreateNewModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Model Oluştur"])},
    "SaveAsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod Olarak Kaydet"])},
    "EditInputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon için Input Model Düzenleyiniz"])},
    "EditOutputModelForFunction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonksiyon için Output Model Düzenleyiniz"])}
  },
  "SuperAdmin": {
    "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "UserId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Id"])},
    "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Id"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adı"])},
    "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje"])},
    "UserSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Arama"])}
  },
  "Localizations": {
    "Keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Anahtarları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Dil Anahtarı"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Dil Anahtarı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Anahtarını Güncelle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "KeyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Anahtarı Tipi"])},
    "ParentPublicId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Public Id"])}
  },
  "LocalizationKeyTypes": {
    "CustomObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel Nesne"])},
    "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alan"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])},
    "BusinessRuleButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Kuralı Butonu"])},
    "PageLayoutPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarım Paneli"])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "MenuItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü Öğesi"])},
    "SelectListItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listelenecek Öğe"])},
    "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirmesi"])},
    "Button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buton"])}
  },
  "LocalizationLanguages": {
    "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diller"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Dil"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Dil"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Ayarını Güncelle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "LanguageIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil İkon Dosyası"])},
    "LanguageCulture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dil Kısaltması"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])}
  },
  "PageLayoutTemplates": {
    "NewPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Sayfa Tasarımı Şablonu"])},
    "EditPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı Şablonu Güncelle"])},
    "ListPageLayoutTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa Tasarımı Şablonları"])}
  },
  "SetCodeDatabases": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Veritabanı Bağlantıları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Veritabanı Bağlantısı Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Veritabanı Bağlantısı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Veritabanı Bağlantısı Düzenle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "DbType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "InstanceIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İp Adresi"])},
    "SchemaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şema İsmi"])},
    "CnnStringWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Noktası"])},
    "CnnStringRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı Noktası Okuma"])},
    "Postgresql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postgresql"])},
    "Sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MsSql"])}
  },
  "SetCodeModels": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Modelleri"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Modeli Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode Modeli"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode Modeli Düzenle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "ModelCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Kodu"])}
  },
  "CompanyInformation": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Bilgileri"])},
    "DetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Bilgileri"])},
    "GeneralDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel Bilgiler"])},
    "GlobalScripts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Html & Css & Js"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma id"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Adı"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adı"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "CompanyAdminEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Yönetici Email"])},
    "TaxOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Dairesi"])},
    "TaxNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Numarası"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "County": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlçe"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "ContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi"])},
    "ContactPersonEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi Email"])},
    "ContactPersonTel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi Tel"])},
    "IsIpControl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girişte IP Kontrolü Yapılsın"])},
    "AcceptIpAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylanan Ip Adresleri"])},
    "GoogleMapsApiKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps Api Key"])},
    "HTML": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
    "CSS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSS"])},
    "Script": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Script"])}
  },
  "HttpConnections": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SetCode HTTP Bağlantıları"])},
    "NewButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni SetCode HTTP Bağlantısı Ekle"])},
    "NewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Bağlantı Tanımı"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Bağlantısı Düzenle"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "HTTPHeaders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP Headers"])},
    "Json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JSON"])},
    "Xml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XML"])},
    "WarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bir Http Header ekleyebilmek için önce alanları doldurunuz."])},
    "BodyParameterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Parameter Name"])},
    "ReturnType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Type"])},
    "SystemVariables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Variables"])},
    "CustomModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Model"])}
  },
  "Relations": {
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "FormulaName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formül Adı"])},
    "isDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "Rollup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollup"])},
    "Lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup"])},
    "ObjectRelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesne İlişkilendirmesi"])},
    "ViewFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüleme Filtresi"])}
  }
}