import { Modal } from "bootstrap";

const hideModal = function (modalEl = null) {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const showModal = function (modalEl = null) {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getOrCreateInstance(modalEl);
  myModal.show();
};

const removeModals = function () {
  if (document.querySelectorAll(".modal.fade.show").length) {
    document.querySelectorAll(".modal.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const removeModalBackdrops = function () {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const removeTooltips = function () {
  if (document.querySelectorAll(".tooltip[role='tooltip']").length) {
    document.querySelectorAll(".tooltip[role='tooltip']").forEach((item) => {
      item.remove();
    });
  }
};

const removeMoshaNotifications = function () {
  if (document.querySelectorAll(".mosha__toast").length) {
    document.querySelectorAll(".mosha__toast").forEach((item) => {
      item.classList.add("hidden");
    });
  }
};

export {
  removeModals,
  removeModalBackdrops,
  removeMoshaNotifications,
  removeTooltips,
  showModal,
  hideModal,
};
