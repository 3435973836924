<template>
  <div class="dx-viewport">
    <DxDateBox
      class="dx-datepicker-container"
      ref="datePicker"
      :value="value"
      :type="type"
      :displayFormat="displayFormat"
      :showClearButton="true"
      :disabled="isDisabled"
      :onValueChanged="onValueChanged"
      :onFocusIn="onFocus"
      :input-attr="inputAttributes"
      :interval="String.isNullOrWhiteSpace(interval) ? 30 : interval"
      :applyValueMode="applyValueMode"
      :useMaskBehavior="true"
    />
  </div>
</template>
<script>
/* https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/ */
import DxDateBox from "devextreme-vue/date-box";
export default {
  name: "DatePicker",
  props: [
    "value", //input value
    "interval", //Specifies the interval between neighboring values in the popup list in minutes
    "id", //label on click focused input
    "type", //time,date,datetime
    "displayFormat", //locale format
    "isRequired", //field is required?
    "isDisabled", //field is disabled?
    "isReturnMaskedValue", // on value changed return masked value?
  ],
  emits: ["valueChanged"],
  data() {
    return {
      inputAttributes: {
        required: this.isRequired,
        class: "form-control",
        id: this.id,
      },
      applyValueMode:
        this.type == "date" || this.type == "time" ? "instantly" : "useButtons",
    };
  },
  components: {
    DxDateBox,
  },
  methods: {
    onFocus(e) {
      e.component.open();
    },
    onValueChanged(e) {
      var value = null;
      if (!String.isNullOrWhiteSpace(e.value)) {
        value = e.component._maskValue;
        if (this.isReturnMaskedValue) {
          value = e.component._changedValue;
        }
      }

      this.$emit("valueChanged", value);
    },
  },
};
</script>
