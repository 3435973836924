<template>
  <div
    class="modal fade"
    id="translationModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="translationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`translationModalLabel`">
            <i class="bi bi-translate"></i>
            {{
              $t(
                "Components.TranslationModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close btn-translation-modal-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="isLoading">
            <SetLoader />
          </template>
          <template v-else>
            <form class="form-translation">
              <div v-if="errors.length > 0" class="alert alert-warning">
                <ul class="mb-0">
                  <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div class="row">
                <div class="col md-6">
                  <label for="Name" class="form-label required"
                    >{{
                      $t(
                        "CustomButtons.Name",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="TranslationKey"
                    v-model="model.name"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    readonly
                  />
                </div>
                <div class="col md-6">
                  <label for="Name" class="form-label required"
                    >{{
                      $t(
                        "Localizations.KeyType",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="TranslationKeyType"
                    v-model="model.localizationKeyItemType"
                    :autocomplete="this.$isAutoComplete"
                    :spellcheck="this.$isTextSpellCheck"
                    readonly
                  />
                </div>
              </div>
            </form>
            <div class="table-responsive mt-4">
              <table
                class="table table-bordered table-hover table-translation-items"
              >
                <thead class="table-primary">
                  <tr>
                    <th class="text-center cw-50">
                      {{
                        $t(
                          "BaseModelFields.SerialNo",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-capitalize">
                      {{
                        $t(
                          "Components.TranslationModal.Language",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-capitalize">
                      {{
                        $t(
                          "Components.TranslationModal.TranslationKey",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-capitalize">
                      {{
                        $t(
                          "Components.TranslationModal.TranslationValue",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      item, index
                    ) in model.availableLanguagesWithTranslations"
                    :key="item.key"
                  >
                    <td class="align-middle text-center">
                      <span class="badge align-middle badge-primary">
                        {{ ++index }}
                      </span>
                    </td>
                    <td class="align-middle">
                      {{ item.name }}
                    </td>
                    <td class="align-middle">
                      {{ formulaName }}
                    </td>
                    <td class="align-middle">
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.translationValue"
                        :autocomplete="this.$isAutoComplete"
                        :spellcheck="this.$isTextSpellCheck"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>
        <div class="modal-footer p-4" v-if="!isLoading">
          <button
            type="button"
            class="btn btn-success btn-translation-save"
            @click="updateTranslations"
          >
            <span>
              <i class="bi bi-save me-1"></i>
              {{
                $t(
                  "Components.JsonPasteModal.Save",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "translationModal",
  props: ["keyTypeId", "parentPublicId", "formulaName", "keyName"],
  data() {
    return {
      model: {},
      isLoading: false,
      translationItems: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      errors: [],
    };
  },
  watch: {
    formulaName() {
      this.getTranslations();
    },
  },
  methods: {
    getTranslations() {
      this.isLoading = true;
      var self = this;
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-LocalizationGetKeyWithTranslations?keyFormulaName={0}&keyItemTypeId={1}&parentPublicId={2}",
            this.formulaName,
            this.keyTypeId,
            this.parentPublicId
          )
        )
        .then((response) => {
          this.model = response.data;
          this.translationItems =
            response.data.availableLanguagesWithTranslations;
          this.isLoading = false;
          this.model.name = this.keyName;
        })
        .catch(function (error) {
          //alert(error);
          self.isLoading = false;
        });
    },
    updateTranslations() {
      var buttonName = "btn-translation-save",
        button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(
          String.format(".{0},.btn-translation-modal-close", buttonName)
        );

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      this.model.publicId = this.parentPublicId;
      this.$prodGatewayAxios
        .post("/Brs-LocalizationUpdateTranslations", { ...this.model })
        .then((r) => {
          var response = r.data;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);

          if (response.isOk) {
            $(".btn-translation-modal-close").trigger("click");
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            this.errors.push(response.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
};
</script>
